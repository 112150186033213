import {
    PING_INTERVAL,
    DEBUG,
    C_LOST_DURATION,
    C_INIT,
    DISPATCHER_USER_HANGUP_TIMEOUT,
    DISPLAY_ONLY_IN_SESSION,
    RESET_TOGGLE_THRESHOLD,
    STREAM_RECORDING_LIMIT,
    FILE_TYPE_ENDINGS,
    VIDEO_EFFECT_TIMEOUT,
    FOCUS_FEATURE_TIMEOUT,
} from '../config';
import { errorLog, timeLog } from '../helper/logging';
import { formatDataSize, getWebBrowser, runAfterTimeHasElapsed, normalizePhone, getFileTypeCategory } from '../helper/helper';
import { createBystander, sendFileTransferInfo, sendSMSAPI } from '../api/backendApi';
import { addLogDispatch } from '../redux/actions/logs';
import { conversationErrorHandling, sessionErrorHandling } from '../helper/rtcErrorHandling';
import { connectionEndedDispatch, connectionEstablishedDispatch, connectionLostDispatch } from '../redux/actions/connection';
import reduxStore from '../redux/store/index';
import { addSessionImageDispatch, dispatchAddSessionFile } from '../redux/actions/session';
import { convertBlobToBase64, createErrorLog, createKpiLog, isOnStartPage } from '../helper/helper';
import {
    deactivateAudioStreamDispatcherDispatch,
    deactivateBidiDispatch,
    deactivateCallerChat,
    deactivateChatDispatcherDispatch,
    deactivateConferencingDispatch,
    deactivateDrawDispatcherDispatch,
    deactivateExternalStreamDispatch,
    deactivateGPSDispatch,
    deactivateHDSendDispatch,
    deactivatePointerDispatcherDispatch,
    deactivateScreenshareDispatch,
    deactivateSmartConnectDispatch,
    deactivateSnapshotDispatch,
    deactivateStreamRecordingDispatch,
    deactivateVideoDispatcherDispatch,
    disableDrawDispatch,
    disablePointerDispatch,
    disableSnapshotDispatch,
    dispatchCallerFileTransferEnded,
    dispatchStartStreamRecording,
    dispatchStopStreamRecording,
    muteAudioDispatch,
    unmuteAudioDispatch,
} from '../redux/actions/application';
import { dispatchDisallowPaintingDispatcher } from '../redux/actions/paint';
import { addConversationNameDispatcherDispatch, muteMicDispatcherDispatch, unmuteMicDispatcherDispatch } from '../redux/actions/conferencing';
import {
    createUserDisplayName,
    dispatcherStreamHandlers,
    enterConversation,
    getURLParams,
    loadEventListenersDispatcher,
    unloadEventListenersDispatcher,
} from '../helper/rtcFlowHandling';
import {
    dispatchAddDispatcherAudioStream,
    dispatchAddDispatcherBidiStream,
    dispatchAddDispatcherStream,
    dispatchRemoveDispatcherAudioStream,
    dispatchRemoveDispatcherBidiStream,
} from '../redux/actions/stream';
import { ONLY_AUDIO, ONLY_BIDI_VIDEO, SCREENSHARING } from '../redux/reducers/streams';
import { addNotificationAndShowDispatch, hideAndRemoveNotificationsDispatch } from '../redux/actions/notifications';
import {
    denyAudioStreamPermissionDispatch,
    denyScreensharePermissionDispatch,
    denyVideoStreamPermissionDispatch,
    grantAudioStreamPermissionDispatch,
    grantScreensharePermissionDispatch,
    grantVideoStreamPermissionDispatch,
} from '../redux/actions/permissions';
import dispatcherDummyStream from '../resources/video/dispatcherDummyStream.mp4';
import { addFileDispatcherDispatch, addFileUploadQueueDispatch, removeFileUploadQueueDispatch, removePreviewFileDispatch } from '../redux/actions/files';
import {
    dispatchActivateFocusControls,
    dispatchDeactivateFocusControls,
    dispatchResetFocusWindow,
    dispatchSetFocusWindowChat,
    dispatchSetFocusWindowScreenshare,
} from '../redux/actions/focus';
import { FOCUS_FEATURE_TYPE } from '../types';
import { handleContactMessageDispatcher } from '../webrtc/handleContactMessageDispatcher';

/**
 * DispatcherStore
 * contains all the functions needed to setup the dispatcher webrtc connection and communication
 */

class DispatcherStore {
    connected = false;
    connectedSession = null;
    isPhoneNumberConsumed = false;
    phone = null;
    apiKey = null;
    apiRTC = null;
    isPrimaryPlatformUsed = null;
    webRtcAlias = null;
    sender = null;
    bystanderToken = null;
    messages = [];
    newCallCallbacks = [];
    closeCallCallbacks = [];
    callbackOnIncoming = null;
    callerStream = null;
    call = null;
    userId = null;
    sessionId = null;
    bystanderUrlFragment;
    translationError = false;
    isAndroid = false;
    isFirefox = false;
    isIOS = false;
    osMajorVersion = null;
    heartbeatInterval = null;
    pongMissed = 0;
    pongAccepted = 0;
    type = 'dispatcher';
    token = null;
    failedLogins = 0;

    userAgent = null;
    callerId = null;

    connectedConversation = null;
    conversationName = null;

    ongoingStream = null;
    bidiStream = null;
    streamWithEffect = null;
    audioStream = null;
    screenshareStream = null;
    dummyStream = null;

    subscribedStreams = {};

    previousCameraId = null;
    streamRetryRequests = 0;

    stoppedViaBrowserButton = false;
    sessionHijackingActivationTimeout = null;

    /**
     * init the webrtc session event listeners and execute callback on incoming call
     * @param {function} callbackOnIncoming
     */
    initWebRTC = async callbackOnIncoming => {
        await store.createAndJoinConversation();
        callbackOnIncoming();
        this.callbackOnIncoming = callbackOnIncoming;
    };

    /**
     * register a new useragent with apiRTC
     * @param {object} token
     * @returns {Promise}
     */
    registerUserAgentAndStartSession = async ({ token }) => {
        store.userAgent = new store.apiRTC.UserAgent({
            uri: 'apzkey:' + store.apiKey,
        });
        this.token = token;

        return new Promise(function (resolve, reject) {
            store.userAgent
                .register({
                    cloudUrl: store.webRtcAlias,
                    token: token,
                })
                .then(session => {
                    if (DEBUG) addLogDispatch(['user agent session registered']);
                    sessionErrorHandling(session, this);

                    store.connectedSession = session;
                    store.connected = true;
                    store.userId = session.getId();
                    resolve(session.getId());
                    store.setupMessageListener();
                    store.setupFileListener();

                    timeLog('authSession');

                    createKpiLog('infoDispatcherLoginSuccess');
                })
                .catch(error => {
                    if (DEBUG) addLogDispatch(['Registration error', error]);
                    reject(error);

                    store.failedLogins = store.failedLogins + 1;
                    const additionalStates = {
                        0: store.failedLogins,
                    };

                    createErrorLog('infoDispatcherLoginFail', '', '', additionalStates);
                });
        });
    };

    establishConnectivityWithNewCaller(contactInfo) {
        store.sender = contactInfo;
        if (DEBUG) addLogDispatch(['call invitation accepted']);
        createKpiLog('infoConnectionEstablished');
        store.establishHeartbeat();
        connectionEstablishedDispatch();
        store.callbackOnIncoming && store.callbackOnIncoming();
        for (const callback of store.newCallCallbacks) {
            if (typeof callback == 'function') {
                callback();
            }
        }
    }

    /***
     * Send caller latest caller data to conference users
     */

    sendSessionData() {
        const excludeKeys = [
            'bystanderToken',
            'downloadable',
            'whiteLabeling',
            'images',
            'imageFormat',
            'notes',
            'photoPermission',
            'sessionEnd',
            'sessionOpen',
            'sessionStart',
            'streamRecordingPermission',
            'timeToLive',
            'user',
            'userId',
            'geoMap',
            'recordings',
            'fileUploads',
            'videoBackgroundImage',
            'logs',
            'phone',
            'dispatchCenter',
        ];

        const dataForInvitedUsers = Object.keys(reduxStore.getState().session)
            .filter(key => !excludeKeys.includes(key))
            .reduce((acc, key) => {
                acc[key] = reduxStore.getState().session[key];
                return acc;
            }, {});

        dataForInvitedUsers.currentFocusFeature = reduxStore.getState().focus.currentFocusFeature;

        const message = {
            data: 'sessionData',
            sessionData: dataForInvitedUsers,
        };
        store.sendMessageToAllConferenceUsers(message);
    }

    /**
     * Send message to the conference users with application data
     */
    sendApplicationData() {
        const { audioStreamIsActive, chatIsActive, gpsIsActive, externalStreamIsActive } = reduxStore.getState().application;

        this.toggleChat(chatIsActive, true);
        this.toggleGPS(gpsIsActive, true);
        this.sendAudioActivationStatus(audioStreamIsActive);
        this.sendToggleExternalStreaming(externalStreamIsActive);
    }

    /**
     * Send message to the conference users with session handling data
     */
    sendSessionHandlingData() {
        const { activeDeviceId, devices } = reduxStore.getState().sessionHandling;

        const message = {
            data: 'sessionHandlingData',
            activeDeviceId: activeDeviceId,
            devices: devices,
        };
        store.sendMessageToAllConferenceUsers(message);
    }

    /**
     * create and join a session group
     */

    async getUrlParamsAndCreateSessionGroup() {
        const { conversationName } = getURLParams();
        store.connectedSession.joinGroup(conversationName);
        store.connectedSession.leaveGroup('default');
        store.connectedSession.unsubscribeToGroup('default');
    }

    /**
     * Send message to the conference users with the audio status
     */
    sendAudioActivationStatusFromStore() {
        store.sendAudioActivationStatus(reduxStore.getState().application.audioStreamIsActive);
    }

    sendAudioActivationStatus(status) {
        const message = {
            data: 'audioActivationStatus',
            audioIsActive: status,
        };
        store.sendMessageToAllConferenceUsers(message);
    }

    /**
     * Decode token
     */
    getUserFromToken() {
        var base64Url = this.token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        let parsedToken = JSON.parse(jsonPayload);

        return parsedToken.sub;
    }

    /**
     * setup all webrtc contact message eventlisteners
     */
    setupMessageListener() {
        this.connectedSession.removeListener('contactMessage');
        this.connectedSession.on('contactMessage', handleContactMessageDispatcher);
    }

    /**
     * sets up the file invitation listener
     */
    setupFileListener() {
        this.connectedSession.on('fileTransferInvitation', invitation => {
            invitation
                .accept()
                .then(async fileObj => {
                    const base64File = await convertBlobToBase64(fileObj.file);
                    const timestamp = new Date().getTime();
                    this.sendFileTransferEnded();

                    dispatchCallerFileTransferEnded(timestamp, fileObj.type);

                    addSessionImageDispatch({
                        image: base64File,
                        type: fileObj.type,
                        time: timestamp,
                        quality: 'hd',
                    });
                })
                .catch(function (error) {
                    if (DEBUG) addLogDispatch([`Error receiving file invitation - ${error}`]);

                    errorLog({
                        message: 'HD Send Image error receiving file invitation',
                        error: error,
                        eventId: 'HD_IMAGE_FILE_INVITATION_ERROR',
                    });
                });
        });
    }

    createAndJoinConversation() {
        var conversationOptions = {
            moderationEnabled: true,
            moderator: true,
        };
        var createStreamOptions = {};
        createStreamOptions.constraints = {
            audio: false,
            video: false,
        };

        createUserDisplayName(store, 'dispatcher');

        const { callerId, conversationName } = getURLParams();
        store.callerId = callerId;
        store.conversationName = conversationName;

        enterConversation(store, conversationOptions);
        addConversationNameDispatcherDispatch(conversationName);
        store.connectedConversation.join();
        loadEventListenersDispatcher();
        conversationErrorHandling(store.connectedConversation, store);
    }

    handleSendInvitation = () => {
        var contacts = store.connectedConversation.getContacts();
        var keys = Object.keys(contacts);
        var newContact = null;

        newContact = contacts[keys[keys.length - 1]].getUsername();

        var invitationOptions = {
            expirationTime: 30,
        };

        if (newContact !== null) {
            store.connectedConversation.sendInvitation(newContact, invitationOptions);
        }
    };

    stopScreenshare = () => {
        if (store.screenshareStream !== null) {
            store.screenshareStream.removeFromDiv('screenshare-container', 'screenshare-stream');
            if (!store.stoppedViaBrowserButton) {
                store.connectedConversation.unpublish(store.screenshareStream);
                store.screenshareStream.release();
            }
            store.screenshareStream = null;
            store.stoppedViaBrowserButton = false;
            createKpiLog('infoScreenshare', 'unpublished');
        }
    };

    // Share screen in active conversation
    startScreenshare = () => {
        store.apiRTC.Stream.createDisplayMediaStream(SCREENSHARING, false)
            .then(stream => {
                const publishOptions = {
                    context: {
                        screenshare: true,
                    },
                };
                store.screenshareStream = stream;
                dispatchAddDispatcherStream(stream);
                stream.removeFromDiv('screenshare-container', 'screenshare-stream');
                stream.addInDiv('screenshare-container', 'screenshare-stream', {}, true);
                store.connectedConversation.publish(stream, publishOptions);
                dispatcherStreamHandlers(stream);
                // For first activation, log permission request
                if (!reduxStore.getState().permissions.screensharePermission) {
                    createKpiLog('permissionScreenshare', 'granted');
                    grantScreensharePermissionDispatch();
                }
                createKpiLog('infoScreenshare', 'published');
                if (!reduxStore.getState().application.drawIsActive) {
                    dispatchSetFocusWindowScreenshare();
                    store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.SCREEN_SHARE);
                }
            })
            .catch(function (err) {
                addNotificationAndShowDispatch('error.scr_shr_err', 'error', DISPLAY_ONLY_IN_SESSION);
                deactivateScreenshareDispatch('dispatcher');
                // Log permission request denial
                if (reduxStore.getState().permissions.screensharePermission || reduxStore.getState().permissions.screensharePermission === null) {
                    createKpiLog('permissionScreenshare', 'denied');
                    denyScreensharePermissionDispatch();
                }
            });
    };

    startBidi = () => {
        const streamOptions = {
            ...ONLY_BIDI_VIDEO,
        };

        return new Promise((resolve, reject) => {
            store.userAgent
                .createStream(streamOptions)
                .then(stream => {
                    const publishOptions = {
                        context: {
                            bidi: true,
                        },
                    };
                    setTimeout(() => {
                        // Artificial delay to avoid triggering browser autoplay prevention
                        store.bidiStream = stream;
                        dispatchAddDispatcherBidiStream(stream);
                        stream.removeFromDiv('bidiContainer__inner', 'bidi-stream');
                        stream.addInDiv('bidiContainer__inner', 'bidi-stream', {}, true);
                        store.connectedConversation.publish(store.bidiStream, publishOptions);
                        if (!reduxStore.getState().permissions.videoStreamPermission || reduxStore.getState().permissions.videoStreamPermission === null) {
                            // Success
                            createKpiLog('permissionBidi', 'granted');
                            grantVideoStreamPermissionDispatch();
                        }
                        return resolve();
                    }, 1000);
                })
                .catch(() => {
                    addNotificationAndShowDispatch('error.bidiCamera.access', 'error', DISPLAY_ONLY_IN_SESSION);
                    if (reduxStore.getState().permissions.videoStreamPermission === true || reduxStore.getState().permissions.videoStreamPermission === null) {
                        createKpiLog('permissionBidi', 'denied');
                        denyVideoStreamPermissionDispatch();
                    }
                    deactivateBidiDispatch();
                });
        });
    };

    startVideoWithEffect = async effect => {
        const streamOptions = {
            ...ONLY_BIDI_VIDEO,
        };

        const options = {
            backgroundImageUrl: reduxStore.getState().session.videoBackgroundImage,
        };

        return new Promise((resolve, reject) => {
            store.userAgent
                .createStream(streamOptions)
                .then(stream => {
                    const publishOptions = {
                        context: {
                            bidi: true,
                        },
                    };
                    store.bidiStream = stream;

                    // checks for the availablity of cdn dependent video effects. if loading takes longer than the timeout, toggle off
                    let videoEffectTimeout = null;
                    videoEffectTimeout = setTimeout(() => {
                        addNotificationAndShowDispatch('error.video.effect', 'error', DISPLAY_ONLY_IN_SESSION);
                        store.stopBidi();
                        deactivateBidiDispatch();
                    }, VIDEO_EFFECT_TIMEOUT);

                    stream
                        .applyVideoProcessor(effect, options)
                        .then(streamWithEffect => {
                            // Artificial delay to avoid triggering browser autoplay prevention
                            setTimeout(() => {
                                clearTimeout(videoEffectTimeout);
                                store.streamWithEffect = streamWithEffect;
                                store.bidiStream.removeFromDiv('bidiContainer__inner', 'bidi-stream');
                                streamWithEffect.addInDiv('bidiContainer__inner', 'bidi-stream', {}, true);
                                store.connectedConversation.publish(streamWithEffect, publishOptions);
                                if (
                                    !reduxStore.getState().permissions.videoStreamPermission ||
                                    reduxStore.getState().permissions.videoStreamPermission === null
                                ) {
                                    // Success
                                    createKpiLog('permissionBidi', 'granted');
                                    grantVideoStreamPermissionDispatch();
                                }
                                return resolve();
                            }, 1000);
                        })
                        .catch(err => {
                            console.log(err);
                            if (reduxStore.getState().application.bidiIsActive) deactivateBidiDispatch();
                            return reject();
                        });
                    return resolve();
                })
                .catch(err => {
                    console.log(err);
                    addNotificationAndShowDispatch('error.bidiCamera.access', 'error', DISPLAY_ONLY_IN_SESSION);
                    if (reduxStore.getState().permissions.videoStreamPermission === true || reduxStore.getState().permissions.videoStreamPermission === null) {
                        createKpiLog('permissionBidi', 'denied');
                        denyVideoStreamPermissionDispatch();
                    }
                    deactivateBidiDispatch();
                });
        });
    };

    stopBidi = () => {
        if (store.bidiStream !== null) {
            store.bidiStream.release();
            store.bidiStream.removeFromDiv('bidiContainer__inner', 'bidi-stream');
            store.connectedConversation.unpublish(store.bidiStream);
        }
        store.bidiStream = null;

        if (store.streamWithEffect !== null) {
            store.streamWithEffect.release();
            store.streamWithEffect.removeFromDiv('bidiContainer__inner', 'bidi-stream');
            store.connectedConversation.unpublish(store.streamWithEffect);
        }
        store.streamWithEffect = null;

        dispatchRemoveDispatcherBidiStream();
    };

    async handleDispatcherAudioStream(isActive) {
        // none active
        if (!reduxStore.getState().application.conferencingIsActive && !reduxStore.getState().application.audioStreamIsActive) {
            // activating audio if neither conferencing or audio are activated should publish a new audio stream
            if (isActive) {
                await store.createAudioStream();
                if (reduxStore.getState().application.audioIsMuted) unmuteAudioDispatch();
                if (reduxStore.getState().conferencing.micIsMuted) unmuteMicDispatcherDispatch();
            }
        }

        // both active
        if (reduxStore.getState().application.conferencingIsActive && reduxStore.getState().application.audioStreamIsActive) {
            // deactivating audio while conferencing and audio are activated should leave a published audio stream in the conversation
            if (!isActive) {
                // do nothing
                return;
            }
        }

        // only conferencing active
        if (reduxStore.getState().application.conferencingIsActive && !reduxStore.getState().application.audioStreamIsActive) {
            // activating audio while conferencing is activated should do nothing if a published audio stream already exists
            if (isActive) {
                // do nothing unless dispatcher audio stream not active
                if (!reduxStore.getState().streams.dispatcherAudioStream) {
                    await store.createAudioStream();
                    if (reduxStore.getState().application.audioIsMuted) unmuteAudioDispatch();
                    if (reduxStore.getState().conferencing.micIsMuted) unmuteMicDispatcherDispatch();
                }
                return;
            }
        }

        // only audio active
        if (!reduxStore.getState().application.conferencingIsActive && reduxStore.getState().application.audioStreamIsActive) {
            // deactivating audio while only audio is activated should unpublish the audio stream from the conversation
            if (!isActive) {
                store.removeAudioStream();
                if (!reduxStore.getState().conferencing.micIsMuted) muteMicDispatcherDispatch();
                if (!reduxStore.getState().application.audioIsMuted) muteAudioDispatch();
            }
        }
    }

    removeAudioStream() {
        if (reduxStore.getState().streams.dispatcherAudioStream) {
            store.connectedConversation.unpublish(reduxStore.getState().streams.dispatcherAudioStream);
            reduxStore.getState().streams.dispatcherAudioStream.release();
            dispatchRemoveDispatcherAudioStream();
            createKpiLog('infoAudioStream', 'unpublished');
        }
    }

    createAudioStream() {
        return new Promise((resolve, reject) => {
            if (reduxStore.getState().streams.dispatcherAudioStream === null) {
                const streamOptions = { ...ONLY_AUDIO };
                store.userAgent
                    .createStream(streamOptions)
                    .then(stream => {
                        const options = {
                            audioLabels: ['dispatcherAudio'],
                        };
                        store.audioStream = stream;
                        store.connectedConversation.publish(stream, options);
                        dispatchAddDispatcherAudioStream(stream);
                        if (!reduxStore.getState().permissions.audioStreamPermission) {
                            createKpiLog('permissionAudioStream', 'granted');
                            grantAudioStreamPermissionDispatch();
                        }
                        createKpiLog('infoAudioStream', 'published');
                        return resolve();
                    })
                    .catch(() => {
                        addNotificationAndShowDispatch('error.mic.acc', 'error', DISPLAY_ONLY_IN_SESSION);
                        if (
                            reduxStore.getState().permissions.audioStreamPermission === true ||
                            reduxStore.getState().permissions.audioStreamPermission === null
                        ) {
                            createKpiLog('permissionAudioStream', 'denied');
                            denyAudioStreamPermissionDispatch();
                        }
                        return reject();
                    });
            }
        });
    }

    async startConversationRecording() {
        await store.startDummyStream();

        return new Promise((resolve, reject) => {
            let options = {};

            // Dispatcher must have an active stream in the conversation to record streams
            let timestamp = new Date().getTime();
            const fileName = store.callerId + '_' + store.bystanderToken + '_' + timestamp;

            let loadInterval = 0;
            options = {
                labelEnabled: true,
                labels: ['callerVideo', 'callerAudio', 'dispatcherAudio', 'conferenceUserAudio'],
                mode: 'efficient',
                customIdInFilename: fileName,
                ttl: reduxStore.getState().session.timeToLive,
            };

            loadInterval = setInterval(checkIfDummyStreamActiveAndStartRecording, 1000);

            function checkIfDummyStreamActiveAndStartRecording() {
                let streams = store.connectedConversation.getAvailableStreamList();
                if (streams.filter(stream => stream.isRemote !== true)) {
                    clearInterval(loadInterval);

                    store.connectedConversation
                        .startRecording(options)
                        .then(recordingInfo => {
                            const additionalStates = {
                                0: recordingInfo.recordedFileName,
                                1: recordingInfo.mediaURL,
                            };
                            dispatchStartStreamRecording();
                            createKpiLog('infoStreamRecordingStatus', 'started');
                            createKpiLog('infoStreamRecordingInfo', '', additionalStates);
                            return resolve();
                        })
                        .catch(error => {
                            console.error('startRecording', error);
                            return reject();
                        });
                }
            }
        });
    }

    stopConversationRecording() {
        return new Promise((resolve, reject) => {
            if (!reduxStore.getState().application.streamRecordingHasStarted) {
                return resolve;
            }
            store.connectedConversation
                .stopRecording()
                .then(recordingInfo => {
                    reduxStore.getState().application.streamRecordingHasStarted && dispatchStopStreamRecording();
                    reduxStore.getState().session.recordings.length >= STREAM_RECORDING_LIMIT && deactivateStreamRecordingDispatch();
                    createKpiLog('infoStreamRecordingStatus', 'stopped');
                    store.stopDummyStream();
                    return resolve;
                })
                .catch(error => {
                    console.error('stopRecording', error);
                    return reject;
                });
            return resolve;
        });
    }

    async startDummyStream() {
        return new Promise((resolve, reject) => {
            let videoElement = document.getElementById('hiddenCanvas');
            const options = {
                videoOnly: true,
                context: {
                    recording: true,
                },
            };
            videoElement.src = dispatcherDummyStream;
            videoElement.loop = true;
            videoElement.play().then(() => {
                if (videoElement && videoElement.src) {
                    let mediaStream = null;
                    if (getWebBrowser() === 'isFirefox') {
                        mediaStream = videoElement.mozCaptureStream();
                    } else {
                        mediaStream = videoElement.captureStream();
                    }
                    store.userAgent
                        .createStreamFromMediaStream(mediaStream)
                        .then(stream => {
                            store.dummyStream = stream;
                            store.connectedConversation.publish(stream, options);
                            return resolve(stream);
                        })
                        .catch(err => {
                            console.log(err);
                            return reject();
                        });
                }
            });
        });
    }

    stopDummyStream() {
        if (store.dummyStream !== null) {
            store.dummyStream.release();
            store.connectedConversation.unpublish(store.dummyStream);
        }
        store.dummyStream = null;
    }

    // Leave active conversation

    leaveConference() {
        if (store.connectedConversation !== null) {
            store.connectedConversation
                .leave()
                .then(() => {
                    if (store.screenshareStream !== null) {
                        store.connectedConversation.unpublish(store.screenshareStream);
                        store.screenshareStream.release();
                    }

                    store.screenshareStream = null;
                    store.connectedConversation.destroy();
                    unloadEventListenersDispatcher();
                    store.connectedConversation = null;
                })
                .catch(err => {
                    console.error('Conversation leave error', err);
                });
        }

        if (store.bidiStream !== null) {
            store.bidiStream.release();
            store.bidiStream = null;
        }

        store.name = null;
        store.subscribedStreams = {};
    }

    muteMic() {
        if (store.audioStream !== null) {
            store.audioStream.disableAudio();
        }
    }

    unmuteMic() {
        if (store.audioStream !== null) {
            store.audioStream.enableAudio();
        }
    }

    async pushFileToChat(description = '') {
        const blob = await fetch(reduxStore.getState().files.previewFile.fileUrl).then(response => response.blob());
        let file = null;

        if (blob instanceof File) {
            file = blob;
        } else if (blob instanceof Blob) {
            file = new File([blob], reduxStore.getState().files.previewFile.name, { type: reduxStore.getState().files.previewFile.type });
        }

        const fileDescriptor = {
            file: file,
            filetype: reduxStore.getState().files.previewFile.extension,
            ttl: reduxStore.getState().session.timeToLive,
        };

        const fileExtension = Object.entries(FILE_TYPE_ENDINGS).find(([key, value]) => key === file.type);
        const fileUrl = URL.createObjectURL(file);
        const formattedFileSize = formatDataSize(file.size);

        removePreviewFileDispatch();
        addFileUploadQueueDispatch({ fileUrl: fileUrl, name: file.name, size: formattedFileSize, type: file.type, extension: fileExtension[1], description });

        store.connectedConversation
            .pushData(fileDescriptor)
            .then(cloudMediaInfo => {
                const timestamp = Date.now();
                const fileInfo = {
                    ...cloudMediaInfo,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    extension: fileExtension[1],
                    description,
                    time: timestamp,
                };
                addFileDispatcherDispatch({ ...fileInfo, size: formattedFileSize });
                dispatchAddSessionFile(file.name, getFileTypeCategory(file), timestamp, cloudMediaInfo.url);
                sendFileTransferInfo(fileInfo, reduxStore.getState().session.timeToLive);
                removeFileUploadQueueDispatch({
                    fileUrl: fileUrl,
                });
                createKpiLog('fileTransmissionDispatcherInfo', '', { 0: file.name, 1: formattedFileSize });

                if (reduxStore.getState().application.currentFocusFeature !== FOCUS_FEATURE_TYPE.CHAT) {
                    store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.CHAT);
                    dispatchSetFocusWindowChat();
                }

                const message = {
                    data: 'dispatcherUploadedFile',
                    fileInfo: fileInfo,
                };

                this.sendMessage(message);
            })
            .catch(error => {
                console.log('File uploading error: ', error);
                addNotificationAndShowDispatch('error.file.upload', 'error', DISPLAY_ONLY_IN_SESSION);
                removeFileUploadQueueDispatch({
                    fileUrl: fileUrl,
                });
            });
    }

    toggleOffAllFeatures() {
        if (reduxStore.getState().application.videoIsActive) deactivateVideoDispatcherDispatch();
        if (reduxStore.getState().application.gpsIsActive) deactivateGPSDispatch();
        if (reduxStore.getState().application.snapshotIsActive) deactivateSnapshotDispatch();
        if (reduxStore.getState().application.pointerIsActive) deactivatePointerDispatcherDispatch();
        if (reduxStore.getState().application.chatIsActive) {
            deactivateChatDispatcherDispatch();
            if (reduxStore.getState().focus.currentFocusFeature === FOCUS_FEATURE_TYPE.CHAT) dispatchResetFocusWindow();
        }
        if (reduxStore.getState().application.hdSendIsActive) deactivateHDSendDispatch();
        if (reduxStore.getState().application.drawIsActive) deactivateDrawDispatcherDispatch();
        if (reduxStore.getState().application.audioStreamIsActive) deactivateAudioStreamDispatcherDispatch();
        if (reduxStore.getState().application.bidiIsActive) deactivateBidiDispatch();
        if (reduxStore.getState().application.streamRecordingIsActive) deactivateStreamRecordingDispatch();
        if (reduxStore.getState().application.screenshareIsActive) deactivateScreenshareDispatch(store.type);
        if (reduxStore.getState().application.conferencingIsActive) deactivateConferencingDispatch();
        if (reduxStore.getState().application.externalStreamIsActive) deactivateExternalStreamDispatch();
        if (reduxStore.getState().application.smartConnectIsActive) deactivateSmartConnectDispatch();
    }

    /**
     * create a bystander user
     * @param {string} phoneNumber
     */
    async createBystander(phoneNumber) {
        store.bystanderToken = await createBystander(phoneNumber).then(result => {
            if (result) return result.token;
        });
        return store.bystanderToken;
    }

    /**
     * set a bystander token
     * @param {string} token
     */
    setBystanderToken(token) {
        store.bystanderToken = token;
        return store.bystanderToken;
    }

    //
    establishHeartbeat() {
        clearInterval(this.heartbeatInterval);
        this.pongMissed = 0;
        this.pongAccepted = 0;

        this.heartbeatInterval = setInterval(() => {
            this.pongMissed += 1; // starts at 1

            this.sendPing();
            this.handlePong();
        }, PING_INTERVAL);
    }

    sendPing() {
        const message = {
            data: 'heartbeat - ping',
        };

        this.sendMessage(message, true);
    }

    sendCurrentFocusFeatureStatus() {
        if (reduxStore.getState().focus.currentFocusFeature !== null) {
            if (reduxStore.getState().focus.currentFocusFeature === FOCUS_FEATURE_TYPE.CHAT) {
                store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.CHAT);
            } else if (reduxStore.getState().focus.currentFocusFeature === FOCUS_FEATURE_TYPE.BIDI) {
                store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.BIDI);
            } else if (reduxStore.getState().focus.currentFocusFeature === FOCUS_FEATURE_TYPE.SCREEN_SHARE) {
                store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.SCREEN_SHARE);
            } else if (reduxStore.getState().focus.currentFocusFeature === FOCUS_FEATURE_TYPE.EXTERNAL_STREAM) {
                store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.EXTERNAL_STREAM);
            }
        }
    }

    handlePong() {
        const missedThreshold = (C_LOST_DURATION + PING_INTERVAL) / PING_INTERVAL; // 4
        const acceptedThreshold = C_LOST_DURATION / PING_INTERVAL; // 3
        const resetToggleThreshold = (RESET_TOGGLE_THRESHOLD + PING_INTERVAL) / PING_INTERVAL; // 10
        const connectionStore = reduxStore.getState().connection;

        if (this.pongMissed >= missedThreshold) {
            this.pongAccepted = 0;
            if (connectionStore.isConnected && connectionStore.status === C_INIT) {
                connectionLostDispatch();
                createKpiLog('infoHeartbeatLost');
            }
        }

        if (this.pongMissed >= resetToggleThreshold) {
            if (reduxStore.getState().application.audioStreamIsActive) deactivateAudioStreamDispatcherDispatch();
            if (reduxStore.getState().application.chatIsActive) deactivateCallerChat();
            if (reduxStore.getState().application.videoIsActive) deactivateVideoDispatcherDispatch();
            if (reduxStore.getState().application.snapshotIsActive) deactivateSnapshotDispatch();
            if (!reduxStore.getState().application.snapshotIsDisabled) disableSnapshotDispatch();
            if (reduxStore.getState().application.pointerIsActive) deactivatePointerDispatcherDispatch();
            if (!reduxStore.getState().application.pointerIsDisabled) disablePointerDispatch();
            if (!reduxStore.getState().application.drawIsDisabled) disableDrawDispatch();
            if (reduxStore.getState().application.drawIsActive) deactivateDrawDispatcherDispatch();
            if (reduxStore.getState().paint.isPaintingAllowed) dispatchDisallowPaintingDispatcher();
            if (reduxStore.getState().application.streamRecordingIsActive) deactivateStreamRecordingDispatch();
            if (reduxStore.getState().application.streamRecordingHasStarted) store.stopConversationRecording();
            if (reduxStore.getState().application.bidiIsActive) deactivateBidiDispatch();
            reduxStore.getState().notifications.currentNotifications.forEach(notification => {
                if (notification.message === 'disclaimer.waiting.caller') {
                    hideAndRemoveNotificationsDispatch('pending');
                }
            });
        }

        if (this.pongAccepted >= acceptedThreshold) {
            if (!reduxStore.getState().connection.isConnected) {
                connectionEstablishedDispatch();
                createKpiLog('infoHeartbeatRegained');

                if (reduxStore.getState().notifications.currentNotifications.length > 0) {
                    reduxStore.getState().notifications.currentNotifications.forEach(notification => {
                        if (notification.message === 'error.lg_evnt') {
                            hideAndRemoveNotificationsDispatch('error');
                        }
                    });
                }
                if (store.isIOS && reduxStore.getState().application.videoIsActive) {
                    store.toggleVideo(false);
                    setTimeout(() => {
                        store.toggleVideo(true);
                    }, 1500);
                }
                // send current feature focus state to caller on connection re-established
                store.sendCurrentFocusFeatureStatus();
            }
        }
    }

    /**
     * send a sms
     * @param {boolean} dryRun
     */
    async sendSMS(dryRun = false, isResendSMS = false) {
        const data = {
            token: this.bystanderToken,
            bystanderId: this.userId,
            phoneNumber: normalizePhone(this.phone),
            dryRun: dryRun,
            isResendSMS: isResendSMS,
        };

        return await sendSMSAPI(data);
    }

    /**
     * send a message via webrtc
     * @param {object} message2Send
     * @param {boolean} ping - is heartbeat ping
     */
    sendMessage(message2Send, ping = false) {
        if (!isOnStartPage() && this.userAgent !== null && store.connectedConversation !== null) {
            const message = JSON.stringify(message2Send);
            let intervalCount = 5;
            let interval;

            const send = () => {
                if (DEBUG) addLogDispatch(['senderObject', { ...this.sender }]);
                this.sender
                    .sendMessage(message)
                    .then(function () {
                        if (DEBUG) addLogDispatch(['message send', message]);
                    })
                    .catch(err => {
                        // failed message attempts are not relevant if dispatcher is on start dashboard so don't log these
                        if (!isOnStartPage() && this.userAgent !== null) {
                            if (DEBUG) addLogDispatch(['message send error', message, err]);
                            if (!ping) {
                                errorLog({
                                    message: `Error sending message via rtc - dispatcher - ${message}`,
                                    error: err,
                                    eventId: 'MESSAGE_SEND',
                                });
                            }
                        }
                    });
            };

            if (this.sender) {
                send();
                return;
            }

            interval = window.setInterval(() => {
                if (DEBUG) addLogDispatch(['sendMessageInterval']);
                if (this.sender) {
                    send();
                    clearInterval(interval);
                } else {
                    if (intervalCount > 0) {
                        intervalCount -= 1;
                    } else {
                        clearInterval(interval);
                        if (DEBUG) addLogDispatch(['message could not be send -> no sender']);
                    }
                }
            }, 200);
        }
    }

    sendMessageToUser(message2Send, user) {
        const message = JSON.stringify(message2Send);
        let intervalCount = 5;
        let interval;

        if (user) {
            const send = () => {
                if (DEBUG) addLogDispatch(['senderObject', { ...user }]);
                if (typeof user.sendMessage === 'function') {
                    user.sendMessage(message)
                        .then(function () {
                            if (DEBUG) addLogDispatch(['message send', message]);
                        })
                        .catch(err => {
                            if (DEBUG) addLogDispatch(['message send error', message, err]);
                        });
                }
            };

            if (user) {
                send();
                return;
            }

            interval = window.setInterval(() => {
                if (DEBUG) addLogDispatch(['sendMessageInterval']);
                if (this.sender) {
                    send();
                    clearInterval(interval);
                } else {
                    if (intervalCount > 0) {
                        intervalCount -= 1;
                    } else {
                        clearInterval(interval);
                        if (DEBUG) addLogDispatch(['message could not be send -> no sender']);
                    }
                }
            }, 200);
        }
    }

    sendMessageToAllConferenceUsers(message2Send) {
        if (store.connectedConversation && store.connectedConversation !== null) {
            const { callerId } = getURLParams();
            const message = JSON.stringify(message2Send);
            let intervalCount = 5;
            let interval;
            let contacts = store.connectedConversation.getContacts();
            let keys = Object.keys(contacts);

            const send = () => {
                for (const element of keys) {
                    if (contacts[element].userData.username !== callerId) {
                        if (DEBUG) addLogDispatch(['senderObject', { ...contacts[element] }]);
                        contacts[element]
                            .sendMessage(message)
                            .then(function () {
                                if (DEBUG) addLogDispatch(['message send', message]);
                            })
                            .catch(err => {
                                if (DEBUG) addLogDispatch(['message send error', message, err]);
                            });
                    }
                }
            };

            if (contacts) {
                send();
                return;
            }

            interval = window.setInterval(() => {
                if (DEBUG) addLogDispatch(['sendMessageInterval']);
                if (this.sender) {
                    send();
                    clearInterval(interval);
                } else {
                    if (intervalCount > 0) {
                        intervalCount -= 1;
                    } else {
                        clearInterval(interval);
                        if (DEBUG) addLogDispatch(['message could not be send -> no sender']);
                    }
                }
            }, 200);
        }
    }

    // tested
    /**
     * add a new callback to call accepted event
     * @param {function} callback
     */
    addNewCallCallback(callback) {
        this.newCallCallbacks.push(callback);
    }

    // tested
    /**
     * add a new callback to call ended event
     * @param {function} callback
     */
    addCloseCallCallback(callback) {
        this.closeCallCallbacks.push(callback);
    }

    // tested
    /**
     * clear all call callbacks
     */
    clearCallCallbacks() {
        this.newCallCallbacks = [];
        this.closeCallCallbacks = [];
    }

    // tested
    /**
     * logout from current session
     */
    logout() {
        this.closeSession();
        if (this.connectedSession) {
            this.connectedSession.disconnect();
            this.connectedSession = null;
        }
        this.connected = false;
    }

    // not tested - trivial and extarnal
    /**
     * logout after unload event
     */
    unloadHandler() {
        this.connectedSession.disconnect();
        this.connectedSession = null;
    }

    async sendDispatcherLeftToCaller() {
        const message = {
            data: 'dispatcherLeft',
        };

        this.sendMessage(message, true);
    }

    sendDispatcherLeftToUser(contact) {
        const message = {
            data: 'dispatcherLeft',
        };

        this.sendMessageToUser(message, contact);
    }

    sendJoinRequestGrantedToUser(contact) {
        const message = {
            data: 'joinRequestIsGranted',
        };

        this.sendMessageToUser(message, contact);
    }

    sendJoinRequestDeclinedToUser(contact) {
        const message = {
            data: 'joinRequestIsDeclined',
        };

        this.sendMessageToUser(message, contact);
    }

    sendHandoverSessionToCallerDevice(contact) {
        const message = {
            data: 'handoverSession',
        };

        this.sendMessageToUser(message, contact);
    }

    sendDispatcherLeftToConferenceUsers() {
        const message = {
            data: 'dispatcherLeft',
        };

        this.sendMessageToAllConferenceUsers(message);
    }

    sendSetFeatureFocus(feature) {
        const message = {
            data: 'setFeatureFocus',
            state: feature,
        };

        this.sendMessage(message);
        dispatchDeactivateFocusControls();
        runAfterTimeHasElapsed(dispatchActivateFocusControls, FOCUS_FEATURE_TIMEOUT);
    }

    sendToggleExternalStreaming(activeState) {
        const message = {
            data: 'toggleExternalStreaming',
            state: activeState,
        };

        this.sendMessage(message);
        this.sendMessageToAllConferenceUsers(message);
    }

    async toggleMicrophone(activeState) {
        const message = {
            data: 'toggleMicrophone',
            state: activeState,
        };

        this.sendMessage(message);
    }

    // tested
    /**
     * close the session
     */
    async closeSession() {
        this.closeCallCallbacks.forEach(currentCloseCallCallback => {
            if (typeof currentCloseCallCallback == 'function') {
                currentCloseCallCallback();
            }
        });

        setTimeout(() => {
            if (this.connectedConversation) {
                this.connectedConversation.leave();
                this.handleDispatcherAudioStream(false);
            }
            this.userAgent
                .unregister()
                .then(() => {
                    console.log('Disconnected from rtc platform');
                })
                .catch(error => {
                    console.log('error disconnecting during unregistration: ', error);
                });
            this.phone = null;
            this.isPhoneNumberConsumed = false;
            this.sender = null;
            this.sessionId = null;
            this.bystanderToken = null;
            this.userAgent = null;
            clearInterval(this.heartbeatInterval);
            connectionEndedDispatch();

            if (window && window.apiRTC) {
                delete window.apiRTC;
            }
        }, DISPATCHER_USER_HANGUP_TIMEOUT);
    }

    // tested
    /**
     * init a new session
     * @param {string} phone
     */
    initSession(phone) {
        this.phone = phone;
        this.sessionId = Math.random().toString().substring(2);
    }

    // tested
    /**
     * toggle gps
     * @param {boolean} activeState
     * @param {boolean} onlyToConference - send data only to the conference users
     */
    toggleGPS(activeState, onlyToConference = false) {
        const message = {
            data: 'toggleGPS',
            state: activeState,
        };

        if (!onlyToConference) this.sendMessage(message);
        this.sendMessageToAllConferenceUsers(message);
    }

    // tested
    /**
     * toggle video
     * @param {boolean} activeState
     */
    toggleVideo(activeState) {
        const message = {
            data: 'toggleVideo',
            state: activeState,
            id: reduxStore.getState().application.deviceId,
            name: reduxStore.getState().application.deviceName,
        };

        this.sendMessage(message);
    }

    // tested
    /**
     * toggle chat
     * @param {boolean} activeState
     * @param {boolean} onlyToConference - send data only to the conference users
     */
    toggleChat(activeState, onlyToConference = false) {
        const message = {
            data: 'toggleChat',
            state: activeState,
        };
        if (!onlyToConference) this.sendMessage(message);
        this.sendMessageToAllConferenceUsers(message);
    }

    /**
     * toggle caller audio
     * @param {boolean} isActive
     */
    toggleAudioStream(isActive) {
        this.handleDispatcherAudioStream(isActive);

        const message = {
            data: 'toggleAudioStream',
            state: isActive,
        };
        this.sendMessage(message);
    }

    /**
     * toggle stream recording
     * @param {boolean} isActive
     */
    toggleStreamRecording(isActive) {
        const message = {
            data: 'toggleStreamRecording',
            state: isActive,
        };
        this.sendMessage(message);
    }

    // tested
    /**
     * toggle snapshot
     * @param {boolean} activeState
     */
    toggleSnapshot(activeState) {
        const message = {
            data: 'toggleSnapshot',
            state: activeState,
        };
        this.sendMessage(message);
    }

    // not tested
    /**
     * toggle pointer
     * @param {boolean} activeState
     */
    togglePointer(activeState) {
        const message = {
            data: 'togglePointer',
            state: activeState,
        };
        this.sendMessage(message);
    }

    // not tested
    /**
     * send pointer position
     * @param {object} position
     */
    sendPointerPosition(position) {
        const message = {
            data: 'pointerPosition',
            state: position,
        };
        this.sendMessage(message);
    }

    // not tested
    /**
     * toggle hdsend
     * @param {boolean} activeState
     */
    toggleHDSend(activeState) {
        const message = {
            data: 'toggleHDSend',
            state: activeState,
        };
        this.sendMessage(message);
    }

    // not tested
    /**
     * toggle hdsend
     * @param {boolean} activeState
     */
    sendScreenshareToggled(activeState) {
        const message = {
            data: 'screenShareToggled',
            state: activeState,
        };

        this.sendMessageToAllConferenceUsers(message);
    }

    sendMuteAllMicrophones() {
        const message = {
            data: 'toggleMicrophone',
        };

        this.sendMessageToAllConferenceUsers(message);
    }

    sendMuteMicrophone(conferenceUser) {
        const message = {
            data: 'toggleMicrophone',
        };

        this.sendMessageToUser(message, conferenceUser);
    }

    sendBidiIsDeactivated() {
        const message = {
            data: 'bidiIsDeactivated',
        };

        this.sendMessage(message);
    }

    // not tested
    /**
     * send transfer ended message
     */
    sendFileTransferEnded() {
        const message = {
            data: 'hdFileTransferEnded',
        };
        this.sendMessage(message);
    }

    /**
     * sends the last new points
     * @param {string} points
     */
    sendDispatcherPaintPoints(points) {
        const message = {
            data: 'dispatcherPaintPoints',
            points,
        };
        this.sendMessage(message);
    }

    /**
     * sends the undo message for the last painted points
     */
    sendUndoPaintPoints() {
        const message = {
            data: 'undoLastPaintPoints',
        };
        this.sendMessage(message);
    }

    /**
     * sends the delete message for all painted points
     */
    sendDeletePaintPoints() {
        const message = {
            data: 'deleteAllPaintPoints',
        };
        this.sendMessage(message);
    }

    /**
     * toggle draw
     * @param {boolean} activeState
     */
    toggleDraw(activeState) {
        const message = {
            data: 'toggleDraw',
            state: activeState,
        };
        this.sendMessage(message);
    }

    /**
     * allowPainting
     * @param {boolean} activeState
     */
    sendAllowPainting(activeState) {
        const message = {
            data: 'allowPainting',
            state: activeState,
        };
        this.sendMessage(message);
    }

    /**
     * allowPainting to conference users
     * @param {boolean} activeState
     */
    sendAllowPaintingToConferenceUsers(activeState) {
        const message = {
            data: 'allowPainting',
            state: activeState,
        };

        this.sendMessageToAllConferenceUsers(message);
    }
    /**
     * toggle conferencing
     * @param {boolean} activeState
     */
    toggleConferencing(activeState) {
        const message = {
            data: 'toggleConferencing',
            state: activeState,
        };
        this.sendMessage(message);
    }
}

export let store = new DispatcherStore();
