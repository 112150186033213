export type UserType = 'caller' | 'dispatcher' | 'conference';

export type FocusFeatureType = typeof FOCUS_FEATURE_TYPE;
export type FocusFeatureKey = keyof FocusFeatureType;
export enum InviteType {
    EMAIL = 'EMAIL',
    PHONE = 'PHONE',
}

export enum DispatchMode {
    ADD = 'ADD',
    EDIT = 'EDIT',
}

export const FOCUS_FEATURE_TYPE = {
    LIVE_VIDEO: 'LIVE_VIDEO',
    CHAT: 'CHAT',
    BIDI: 'BIDI',
    SCREEN_SHARE: 'SCREEN_SHARE',
    RESET_FOCUS: 'RESET',
    MAP: 'MAP',
    EXTERNAL_STREAM: 'EXTERNAL_STREAM',
};

export const INVITATION_UPDATE_MODE = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
};

export type MatchParams = {
    id: string;
    sessionId: string;
};

export type Match = {
    params: MatchParams;
    isExact: boolean;
    path: string;
    url: string;
};

export type InvitationDetails = {
    recipient: string;
    type?: InviteType;
    isSending?: boolean;
    hasError?: boolean;
    isInEditMode?: boolean;
    wasPreviouslyDispatched?: boolean;
};

export const FILE_TYPE = {
    VIDEO: 'video',
    AUDIO: 'audio',
    IMAGE: 'img',
    DOCUMENT: 'doc',
    PRESENTATION: 'present',
    TABLE: 'table',
};

export const RECORDING_TYPE = {
    VIDEO: 'video',
    AUDIO: 'audio',
};

export const SMART_CONNECT_FEATURES = {
    SESSION_HANDOVER: 'SESSION_HANDOVER',
    EXTERNAL_STREAM: 'EXTERNAL_STREAM',
};

export const SESSION_DEVICE_CHANGE_HINTS = {
    NEW_DEVICE: 'NEW_DEVICE',
    DEVICE_CHANGED: 'DEVICE_CHANGED',
};

export type SessionHandling = {
    sessionHandoverIsActive: boolean;
    sessionHandoverSuccessShown: boolean;
    sessionHijackingIsActive: boolean;
    isHeadMountedDisplayDevice: boolean;
    activeDeviceId: Number | null;
    devices: any;
};
