import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { store } from '../../store/DispatcherStore';
import { calcDuration, notesLog } from '../../helper/logging';
import { deleteBystander, sendSessionEndInfo, deleteExternalStream } from '../../api/backendApi';
import { createKpiLog, replaceText } from '../../helper/helper';
import { addNotificationAndShowDispatch, hideAndRemoveNotificationsDispatch } from '../../redux/actions/notifications';
import { resetAllDispatch } from '../../redux/actions/application';
import { resetSessionDispatch, stopSessionDispatch } from '../../redux/actions/session';
import { dispatchResetPaint } from '../../redux/actions/paint';
import { DISPLAY_ONLY_IN_SESSION } from '../../config';
import { addConversationNameDispatcherDispatch } from '../../redux/actions/conferencing';
import { unpublishStreamAndRemoveFromRedux } from '../../helper/rtcFlowHandling';
import { setHdPhotoDisclaimerNotClickedDispatch, setSnapshotDisclaimerNotAcceptedDispatch } from '../../redux/actions/disclaimers';
import { resetFilesDispatch } from '../../redux/actions/files';
import { dispatchResetInvitedUsers } from '../../redux/actions/invitedUsers';
import { dispatchRemoveExternalStreamUrl } from '../../redux/actions/stream';

/**
 * CloseSessionButton
 * Closes the current session by deleting the bystander user through a backend api.
 * Redirects back to the phone number container.
 */

class CloseSessionButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logoutTriggered: false,
        };
    }

    closeSessionHandler = async () => {
        store.sendDispatcherLeftToCaller();
        store.sendDispatcherLeftToConferenceUsers();

        if (this.props.connectionStatus === 'connection_waiting') resetSessionDispatch();
        store.stopScreenshare();
        if (this.props.dispatcherAudioStream) {
            unpublishStreamAndRemoveFromRedux(this.props.dispatcherAudioStream, store);
        }
        if (this.props.notesIsActive) {
            await notesLog({
                message: this.props.notes,
                eventId: 'SESSION_NOTES',
            });
        }
        if (this.props.externalStreamUrl !== null && this.props.externalStreamIsActive) {
            await deleteExternalStream();
            dispatchRemoveExternalStreamUrl();
        }

        dispatchResetInvitedUsers();

        if (this.props.streamRecordingIsActive) {
            let stopRecordingInterval = 0;

            const hasRecordingStopped = () => {
                if (!this.props.streamRecordingHasStarted) {
                    clearInterval(stopRecordingInterval);
                    this.logEndOfSessionAndReset();
                }
            };

            stopRecordingInterval = setInterval(hasRecordingStopped, 500);
            addNotificationAndShowDispatch('info.rec.stop', 'info', DISPLAY_ONLY_IN_SESSION);
            await store.stopConversationRecording();
        } else {
            this.logEndOfSessionAndReset();
        }
    };

    logEndOfSessionAndReset = async () => {
        const sessionDuration = calcDuration('session');
        const additionalStates = {
            0: sessionDuration,
        };

        createKpiLog('infoSessionEnded', '', additionalStates);
        await sendSessionEndInfo({ duration: sessionDuration });
        store.leaveConference();
        stopSessionDispatch();
        deleteBystander(store.phone);
        await store.closeSession();
        resetAllDispatch();
        addConversationNameDispatcherDispatch(null);
        dispatchResetPaint();
        this.setState({ logoutTriggered: true });
        hideAndRemoveNotificationsDispatch();
        setSnapshotDisclaimerNotAcceptedDispatch();
        setHdPhotoDisclaimerNotClickedDispatch();
        resetFilesDispatch();
    };

    unload = event => {
        addNotificationAndShowDispatch(replaceText(this.props.texts, 'system.closeError'), 'error', DISPLAY_ONLY_IN_SESSION);
        this.closeSessionHandler();
        store.unloadHandler();
        event.preventDefault();
        event.returnValue = '';
        return '';
    };

    componentDidMount() {
        if (this.props.closeSessionClick) {
            this.closeSessionHandler();
        }
        window.addEventListener('beforeunload', this.unload);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.unload);
    }

    render() {
        if (this.state.logoutTriggered) {
            return <Redirect to={'/new'} />;
        }
        return (
            <button className="btn btn--secondary" onClick={this.closeSessionHandler}>
                {replaceText(this.props.texts, 'close.button')}
            </button>
        );
    }
}
// PropTypes for this Component
CloseSessionButton.propTypes = {
    match: PropTypes.any,
    texts: PropTypes.any,
    closeSessionClick: PropTypes.bool,
    dispatcherAudioStream: PropTypes.object,
    notes: PropTypes.string,
    notesIsActive: PropTypes.bool,
    streamRecordingIsActive: PropTypes.bool,
    streamRecordingHasStarted: PropTypes.bool,
    connectionStatus: PropTypes.string,
    externalStreamUrl: PropTypes.string,
    externalStreamIsActive: PropTypes.bool,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        dispatcherAudioStream: state.streams.dispatcherAudioStream,
        notes: state.session.notes,
        notesIsActive: state.application.notesIsActive,
        streamRecordingIsActive: state.application.streamRecordingIsActive,
        streamRecordingHasStarted: state.application.streamRecordingHasStarted,
        connectionStatus: state.connection.status,
        externalStreamUrl: state.streams.externalStreamUrl,
        externalStreamIsActive: state.application.externalStreamIsActive,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(CloseSessionButton);
