import { getApizeeKey, getAuthtoken, getImage, getSessionEndMessagesConference } from '../api/backendApi';
import { DEBUG, PRIMARY_WEB_RTC_PLATFORM, SECONDARY_WEB_RTC_PLATFORM } from '../config';
import { errorLog } from '../helper/logging';
import {
    createUserDisplayName,
    enterConversation,
    getURLParams,
    loadEventListenersConferenceUser,
    unloadEventListenersConferenceUser,
} from '../helper/rtcFlowHandling';
import {
    activateChatConferenceDispatch,
    activateGPSDispatch,
    deactivateChatConferenceDispatch,
    deactivateGPSDispatch,
    activateAudioStreamCallerDispatch,
    deactivateAudioStreamCallerDispatch,
    activateExternalStreamDispatch,
    deactivateExternalStreamDispatch,
} from '../redux/actions/application';
import { muteMicContactDispatch, setIsLeavingConferenceDispatch } from '../redux/actions/conferencing';
import { dispatchSetIncomingFocusWindow } from '../redux/actions/focus';
import { addLogDispatch } from '../redux/actions/logs';
import { addNotificationAndShowDispatch } from '../redux/actions/notifications';
import { dispatchAllowPainting, dispatchDisallowPainting } from '../redux/actions/paint';
import { denyJoinConversationPermissionDispatch, grantJoinConversationPermissionDispatch } from '../redux/actions/permissions';
import { addSessionGPSDispatch, addSessionInfosDispatch, dispatchAddSessionChatHistory } from '../redux/actions/session';
import { dispatchAddAllDevices, dispatchUpdateActiveDeviceId } from '../redux/actions/sessionHandling';
import { dispatchAddOwnStreamConferenceUser, dispatchRemoveOwnStreamConferenceUser } from '../redux/actions/stream';
import { ONLY_AUDIO } from '../redux/reducers/streams';
import reduxStore from '../redux/store';
class ConferenceStore {
    authToken = null;

    apiRTC = null;
    apiKey = null;
    userAgent = null;

    connectedSession = null;
    connectedConversation = null;
    sessionEndMessages = null;
    imageLogo = null;

    dispatcher = null;
    joinRequestIsGranted = null;

    localStream = null;
    type = 'conference';

    sessionId = null;

    onLeaveHandler = null;

    /**
     * handles the request token generation
     */
    async requestToken() {
        const { sessionId } = getURLParams();

        this.sessionId = sessionId;

        if (!this.authToken) {
            this.authToken = await getAuthtoken(sessionId);
            if (this.authToken) {
                // Proceed
            } else {
                errorLog({
                    sessionId: sessionId,
                    message: `Error getting Authtoken`,
                    error: { stack: 'no authtoken available' },
                    eventId: 'ERROR_AUTHTOKEN',
                });
                throw new Error(`Error getting Authtoken`);
            }
        }
    }

    initConnectionThen(initCallback, errorCallback) {
        this.requestToken()
            .then(async () => {
                this.sessionEndMessages = await getSessionEndMessagesConference(this.authToken);
                this.imageLogo = await getImage({ type: 'blob', endpoint: process.env.REACT_APP_CONFERENCE_LOGO_ENDPOINT }, this.authToken);
                this.registerUser(initCallback);
            })
            .catch(error => {
                errorCallback(error);
            });
    }

    /**
     * register the current useragent with apiRTC, then call the callback
     * @param {function} initCallback
     */
    async registerUser(initCallback) {
        if (window.apiRTC.CloudApi.cloudUrl.includes(PRIMARY_WEB_RTC_PLATFORM)) {
            // hds platform
            conferenceStore.apiKey = await getApizeeKey(this.type, PRIMARY_WEB_RTC_PLATFORM);
            if (DEBUG) addLogDispatch(['using primary web rtc platform:']);
        } else if (window.apiRTC.CloudApi.cloudUrl.includes(SECONDARY_WEB_RTC_PLATFORM)) {
            // cloud platform
            conferenceStore.apiKey = await getApizeeKey(this.type, SECONDARY_WEB_RTC_PLATFORM);
            if (DEBUG) addLogDispatch(['using secondary web rtc platform:']);
        }

        conferenceStore.userAgent = new window.apiRTC.UserAgent({
            uri: 'apzkey:' + conferenceStore.apiKey,
        });

        conferenceStore.userAgent
            .register({
                cloudUrl: window.apiRTC.CloudApi.cloudUrl,
                token: this.authToken,
            })
            .then(session => {
                // Save session
                conferenceStore.connectedSession = session;
                conferenceStore.setupMessageListener();

                if (DEBUG) addLogDispatch(['user agent registered session', session.id]);

                initCallback();
            });
    }

    setupMessageListener() {
        conferenceStore.connectedSession.removeListener('contactMessage');
        conferenceStore.connectedSession.on('contactMessage', conferenceStore.handleContactMessage);
    }

    requestJoinPermission() {
        if (DEBUG) addLogDispatch(['requesting join permission:']);
        const message = {
            data: 'requestJoinPermission',
        };

        this.sendMessage(message);
    }

    async sendIsLeaving() {
        const message = {
            data: 'isLeaving',
        };

        await this.sendMessage(message);
    }

    sendMicrophoneError() {
        const message = {
            data: 'microphoneError',
            userId: conferenceStore.connectedSession.id,
        };

        this.sendMessage(message);
    }

    sendMicrophoneMuted() {
        const message = {
            data: 'microphoneIsMuted',
            userId: conferenceStore.connectedSession.id,
        };

        this.sendMessage(message);
    }

    /**
     * send a given message to dispatcher via webrtc
     * @param {object} message2Send
     * @param {boolean} ping - is heartbeat ping
     */
    sendMessage(message2Send, ping = false) {
        if (this.userAgent !== null) {
            const message = JSON.stringify(message2Send);
            const { sessionId } = getURLParams();
            if (conferenceStore.dispatcher) {
                conferenceStore.dispatcher
                    .sendMessage(message)
                    .then(() => {
                        if (DEBUG) addLogDispatch(['Send message', message]);
                    })
                    .catch(error => {
                        if (DEBUG) addLogDispatch(['error sending messsage', message, error]);

                        if (!ping) {
                            errorLog({
                                sessionId: sessionId,
                                message: `Error sending message via rtc - conference user - ${message}`,
                                error: error,
                                eventId: 'MESSAGE_SEND',
                            });
                        }
                    });
            }
        }
    }

    handleContactMessage = e => {
        const message = JSON.parse(e.content);
        if (message.data === 'toggleMicrophone') {
            conferenceStore.muteMic();
            muteMicContactDispatch();
        }

        if (message.data === 'dispatcherLeft') {
            setIsLeavingConferenceDispatch();
        }

        if (message.data === 'joinRequestIsGranted') {
            grantJoinConversationPermissionDispatch();
            if (DEBUG) addLogDispatch(['join request granted:']);
        }

        if (message.data === 'joinRequestIsDeclined') {
            if (!reduxStore.getState().permissions.joinConversationPermission) denyJoinConversationPermissionDispatch();
            if (DEBUG) addLogDispatch(['join request declined:']);
        }

        if (message.data === 'sessionData') {
            if (message.sessionData.long !== reduxStore.getState().session.long) {
                addSessionGPSDispatch({
                    long: message.sessionData.long,
                    lat: message.sessionData.lat,
                    accuracy: message.sessionData.accuracy,
                });
            }
            if (message.sessionData.browser !== reduxStore.getState().session.browser) {
                addSessionInfosDispatch({
                    osName: message.sessionData.osName,
                    osVersion: message.sessionData.osVersion,
                    browser: message.sessionData.browser,
                });
            }
            if (message.sessionData.chatHistory.length !== 0 && message.sessionData.chatHistory.length !== reduxStore.getState().session.chatHistory.length) {
                dispatchAddSessionChatHistory(message.sessionData.chatHistory);
            }
            if (message.sessionData.currentFocusFeature) {
                dispatchSetIncomingFocusWindow(message.sessionData.currentFocusFeature);
            }
        }

        if (message.data === 'sessionHandlingData') {
            dispatchUpdateActiveDeviceId(message.activeDeviceId);
            dispatchAddAllDevices(message.devices);
        }

        if (message.data === 'audioActivationStatus') {
            if (message.audioIsActive) {
                activateAudioStreamCallerDispatch();
            } else {
                deactivateAudioStreamCallerDispatch();
            }
        }

        if (message.data === 'currentFocusFeature') {
            dispatchSetIncomingFocusWindow(message.currentFocusFeature);
        }

        if (message.data === 'toggleGPS') {
            if (message.state) {
                if (!reduxStore.getState().application.gpsIsActive) {
                    activateGPSDispatch();
                }
            } else {
                deactivateGPSDispatch();
            }
        }

        if (message.data === 'toggleChat') {
            if (message.state) {
                activateChatConferenceDispatch();
            } else {
                deactivateChatConferenceDispatch();
            }
        }

        if (message.data === 'allowPainting') {
            this.allowPainting = message.state;
            if (this.allowPainting) {
                if (!reduxStore.getState().paint.isPaintingAllowed) {
                    dispatchAllowPainting();
                }
            } else {
                dispatchDisallowPainting();
            }
        }

        if (message && message.data === 'toggleExternalStreaming') {
            if (message.state) {
                if (!reduxStore.getState().application.externalStreamIsActive) {
                    activateExternalStreamDispatch();
                }
            } else {
                deactivateExternalStreamDispatch();
            }
        }
    };

    removeStream() {
        if (this.localStream) {
            this.connectedConversation.unpublish(reduxStore.getState().streams.ownStreamConferenceUser);
            this.localStream.release();
            dispatchRemoveOwnStreamConferenceUser(this.localStream.getId());
        }
    }

    addStream() {
        conferenceStore.userAgent
            .createStream(ONLY_AUDIO)
            .then(stream => {
                const options = {
                    audioLabels: ['conferenceUserAudio'],
                };
                // mute stream if mic is muted
                if (reduxStore.getState().conferencing.micIsMuted) {
                    stream.disableAudio();
                }

                conferenceStore.localStream = stream;
                conferenceStore.connectedConversation.publish(conferenceStore.localStream, options);

                dispatchAddOwnStreamConferenceUser(stream);
            })
            .catch(e => {
                addNotificationAndShowDispatch('error.mic.psn', 'info');
                // join conversation without a own stream
                conferenceStore.connectedConversation.join().then(() => {
                    conferenceStore.requestJoinPermission();
                });
                // send error to dispatcher
                this.sendMicrophoneError();
            });
    }

    updateUserName(userName) {
        conferenceStore.connectedSession.setUserData({ username: userName });
    }

    joinConversation(contactId) {
        const { callerId } = getURLParams();
        conferenceStore.dispatcher = conferenceStore.connectedSession.getOrCreateContact(callerId);
        if (DEBUG) addLogDispatch(['dispatcher web rtc contact saved']);

        createUserDisplayName(conferenceStore, contactId);
        if (DEBUG) addLogDispatch(['web rtc session username set']);

        enterConversation(conferenceStore);
        loadEventListenersConferenceUser();

        conferenceStore.connectedConversation
            .join()
            .then(e => {
                if (DEBUG) addLogDispatch(['conversation joined successfully:', e]);
                conferenceStore.requestJoinPermission();
            })
            .catch(error => {
                console.log(error);
            });
    }

    muteMic() {
        conferenceStore.localStream?.disableAudio();
    }

    unmuteMic() {
        conferenceStore.localStream?.enableAudio();
    }

    resetConferenceData() {
        if (conferenceStore.connectedConversation !== null) {
            conferenceStore.connectedConversation
                .leave()
                .then(() => {
                    conferenceStore.connectedConversation.destroy();
                    conferenceStore.connectedConversation = null;
                })
                .catch(err => {
                    console.error('Conversation leave error', err);
                });
        }
    }

    leaveConference() {
        return new Promise((resolve, reject) => {
            unloadEventListenersConferenceUser();

            if (conferenceStore.connectedConversation !== null) {
                conferenceStore.connectedConversation
                    .leave()
                    .then(() => {
                        conferenceStore.connectedConversation.destroy();
                        conferenceStore.connectedConversation = null;
                    })
                    .catch(err => {
                        console.error('Conversation leave error', err);
                    });
            }

            if (conferenceStore.localStream !== null) {
                conferenceStore.localStream.release();
            }

            if (this.userAgent !== null) {
                this.userAgent
                    .unregister()
                    .then(() => {
                        console.log('Disconnected from rtc platform');
                    })
                    .catch(error => {
                        console.log('error disconnecting during unregistration: ', error);
                    })
                    .finally(() => {
                        return resolve();
                    });
            } else {
                return resolve();
            }

            return resolve();
        });
    }
}
export let conferenceStore = new ConferenceStore();
