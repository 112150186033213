import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useSelector } from 'react-redux';

import { store } from '../../store/DispatcherStore';

import ConnectionOverlay from '../Globals/ConnectionOverlay';
import Dropdown from '../Globals/Dropdown';

import { C_LOST, DEBUG, DISPLAY_ONLY_IN_SESSION } from '../../config';
import { addLogDispatch } from '../../redux/actions/logs';

import './CameraChooser.scss';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { createKpiLog, replaceText } from '../../helper/helper';
import { getDevice, parseCameraLabel } from '../../helper/helper';
import {
    activateVideoDispatcherDispatch,
    addMediaDevicesDispatch,
    changeDeviceIdDispatch,
    changeDeviceNameDispatch,
    dispatchSetLiveVideoIsLoading,
} from '../../redux/actions/application';
import { Tick } from '../Icons/Tick';

/**
 * CameraChooser
 * Lets you select a camera from a list of camera options available on the bystander device.
 * Options get transmitted as media devices via an established webRTC connection
 *
 * @component Dropdown - simple custom dropdown
 * @component ConnectionOverlay - shows waiting for connection until webRTC connection is established
 */

class CameraChooser extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        const defaultState = {
            selected: null,
            label: replaceText(this.props.texts, 'dropdown.default.label'),
        };

        this.state = defaultState;

        store.connectedSession.removeListener('contactMessage', this.handleContactMessage);
        store.connectedSession.on('contactMessage', this.handleContactMessage);

        store.addCloseCallCallback(() => {
            if (this._isMounted) {
                this.setState(defaultState);
            }
        });
    }

    handleContactMessage = e => {
        //Display message in UI
        const message = JSON.parse(e.content);
        const sender = e.sender;

        if (this.props.activeDeviceId === null || sender.userData.id === this.props.activeDeviceId) {
            if (message && message.data === 'deviceInfo') {
                if (this._isMounted) {
                    if (message.devices.length > 0) {
                        // getting all media devices
                        addMediaDevicesDispatch(message.devices);

                        // select the current if an id matches label
                        message.devices.forEach(device => {
                            if (device.id === this.state.selected) {
                                this.setState({
                                    label: parseCameraLabel(device.label),
                                });
                            }
                        });

                        if (DEBUG) addLogDispatch(['audio/video devices', message.devices]);

                        let additionalStates = {};

                        message.devices.map((device, index) => {
                            additionalStates = { ...additionalStates, [index]: device.label + ' | ' };
                            return null;
                        });

                        createKpiLog('infoCameraDevices', '', additionalStates);
                    } else {
                        this.setState({
                            label: replaceText(this.props.texts, 'cameradropdown.noAccess'),
                        });
                    }
                }
            }
        }
    };

    // select via dropdown
    handleSelect = (id, parsedLabel) => {
        // change id
        changeDeviceIdDispatch(id);
        changeDeviceNameDispatch(parsedLabel);

        dispatchSetLiveVideoIsLoading();

        this.setState({ selected: id, label: parsedLabel });

        const additionalStates = {
            0: id,
            1: parsedLabel,
        };

        createKpiLog('infoLiveVideoCameraChanged', '', additionalStates);

        activateVideoDispatcherDispatch();
    };

    // change camera label
    changeLabels = () => {
        if (this.state.selected === '0' || this.state.selected === null) {
            const device = getDevice(this.props.deviceId, this.props.devices);

            if (DEBUG) addLogDispatch(['currentDevice', device]);

            if (device) {
                changeDeviceIdDispatch(device.id);
                changeDeviceNameDispatch(device.label);
                this.setState({
                    selected: device.id,
                    label: device.label,
                });
            } else {
                addNotificationAndShowDispatch('error.cmr_chs', 'error', DISPLAY_ONLY_IN_SESSION);
            }
        }
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.videoIsActive && prevProps.videoIsActive !== this.props.videoIsActive) {
            this.changeLabels();
        }

        // preselect device when devices are loaded only if there is no device already preselected
        if (prevProps.devices !== this.props.devices) {
            const device = getDevice(this.props.deviceId, this.props.devices);

            if (device) {
                changeDeviceIdDispatch(device.id);
                changeDeviceNameDispatch(device.label);

                this.setState({
                    label: parseCameraLabel(device.label),
                    selected: device.id,
                });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const isDisabled =
            this.props.forceDisabled ||
            this.props.connectionStatus === C_LOST ||
            !this.props.isConnected ||
            !this.props.videoIsActive ||
            this.props.drawIsActive ||
            this.props.videoIsLoading;

        return (
            <div className="cameraChooser">
                <div>
                    <ConnectionOverlay />
                    <Dropdown
                        disabled={isDisabled}
                        selectChild={true}
                        minHeight={20}
                        clickHandler={this.handleSelect}
                        isLoading={this.props.videoIsLoading}
                        label={this.state.label}
                        id="camera-chooser-dropdown">
                        <CameraOptions devices={this.props.devices} videoIsActive={this.props.videoIsActive} />
                    </Dropdown>
                </div>
            </div>
        );
    }
}

CameraChooser.propTypes = {
    videoIsActive: PropTypes.bool,
    isConnected: PropTypes.bool,
    connectionStatus: PropTypes.string,
    devices: PropTypes.array,
    deviceId: PropTypes.string,
    deviceName: PropTypes.string,
    texts: PropTypes.any,
    forceDisabled: PropTypes.bool,
    drawIsActive: PropTypes.bool,
    osName: PropTypes.string,
    browser: PropTypes.string,
    videoIsLoading: PropTypes.bool,
    activeDeviceId: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        videoIsActive: state.application.videoIsActive,
        drawIsActive: state.application.drawIsActive,
        isConnected: state.connection.isConnected,
        connectionStatus: state.connection.status,
        devices: state.application.devices,
        deviceId: state.application.deviceId,
        deviceName: state.application.deviceName,
        texts: state.texts.texts,
        osName: state.session.osName,
        browser: state.session.browser,
        videoIsLoading: state.application.videoIsLoading,
        activeDeviceId: state.sessionHandling.activeDeviceId,
    };
};

export default connect(mapStateToProps)(CameraChooser);

/**
 * CameraOptions
 * One camera option with label and id.
 */
function CameraOptions({ clickElement, devices, videoIsActive }) {
    const texts = useSelector(state => state.texts.texts);
    const [selectedCamera, setSelectedCamera] = useState('');

    useEffect(() => {
        if (!videoIsActive) {
            setSelectedCamera('');
        }
    }, [videoIsActive]);

    return (
        <div>
            {devices.map(device => (
                <div
                    className="cameraChooser__element"
                    key={device.id}
                    value={device.id}
                    onClick={() => {
                        clickElement(device.id, parseCameraLabel(device.label));
                        setSelectedCamera(device.id);
                    }}>
                    {parseCameraLabel(device.label, texts)}
                    <span>{selectedCamera === device.id && <Tick />}</span>
                </div>
            ))}
        </div>
    );
}

CameraOptions.propTypes = {
    clickElement: PropTypes.func,
    devices: PropTypes.any,
    videoIsActive: PropTypes.bool,
};
