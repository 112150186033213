import store from '../store';

export const ADD_SESSION_DEVICE = 'ADD_SESSION_DEVICE';
export const REMOVE_SESSION_DEVICE = 'REMOVE_SESSION_DEVICE';
export const ACTIVATE_SESSION_HANDOVER = 'ACTIVATE_SESSION_HANDOVER';
export const DEACTIVATE_SESSION_HANDOVER = 'DEACTIVATE_SESSION_HANDOVER';
export const SET_IS_HEAD_MOUNTED_DISPLAY_DEVICE = 'SET_IS_HEAD_MOUNTED_DISPLAY_DEVICE';
export const UNSET_IS_HEAD_MOUNTED_DISPLAY_DEVICE = 'UNSET_IS_HEAD_MOUNTED_DISPLAY_DEVICE';
export const UPDATE_ACTIVE_DEVICE_ID = 'UPDATE_ACTIVE_DEVICE_ID';
export const RESET_SESSION_HANDOVER_DATA = 'RESET_SESSION_HANDOVER_DATA';
export const SHOW_SESSION_HANDOVER_SUCCESS = 'SHOW_SESSION_HANDOVER_SUCCESS';
export const HIDE_SESSION_HANDOVER_SUCCESS = 'HIDE_SESSION_HANDOVER_SUCCESS';
export const ACTIVATE_SESSION_HIJACKING = 'ACTIVATE_SESSION_HIJACKING';
export const DEACTIVATE_SESSION_HIJACKING = 'DEACTIVATE_SESSION_HIJACKING';
export const ADD_CHAT_HISTORY = 'ADD_CHAT_HISTORY';
export const REPLACE_CALLER_DEVICE = 'REPLACE_CALLER_DEVICE';
export const ADD_GEO_LOCATION = 'ADD_GEO_LOCATION';
export const ADD_ALL_DEVICES = 'ADD_ALL_DEVICES';

// ADD DEVICE

const addSessionDevice = data => {
    return {
        type: ADD_SESSION_DEVICE,
        payload: data,
    };
};

export const dispatchAddSessionDevice = data => {
    store.dispatch(addSessionDevice(data));
};

// REMOVE DEVICE

const removeSessionDevice = id => {
    return {
        type: REMOVE_SESSION_DEVICE,
        payload: id,
    };
};

export const dispatchRemoveSessionDevice = id => {
    store.dispatch(removeSessionDevice(id));
};

// REPLACE DEVICE

const replaceCallerDevice = ({ currentDevice, newDevice }) => {
    return {
        type: REPLACE_CALLER_DEVICE,
        payload: {
            currentDevice,
            newDevice,
        },
    };
};

export const dispatchReplaceCallerDevice = ({ currentDevice, newDevice }) => {
    store.dispatch(replaceCallerDevice({ currentDevice, newDevice }));
};

// ACTIVATE HANDOVER

const activateSessionHandover = () => {
    return {
        type: ACTIVATE_SESSION_HANDOVER,
    };
};

export const dispatchActivateSessionHandover = () => {
    store.dispatch(activateSessionHandover());
};

// DEACTIVATE HANDOVER

const deactivateSessionHandover = () => {
    return {
        type: DEACTIVATE_SESSION_HANDOVER,
    };
};

export const dispatchDeactivateSessionHandover = () => {
    store.dispatch(deactivateSessionHandover());
};

// SET IS HEAD MOUNTED DISPLAY

const setIsHeadMountedDisplayDevice = () => {
    return {
        type: SET_IS_HEAD_MOUNTED_DISPLAY_DEVICE,
    };
};

export const dispatchSetIsHeadMountedDisplayDevice = () => {
    store.dispatch(setIsHeadMountedDisplayDevice());
};

// UNSET IS HEAD MOUNTED DISPLAY

const unsetIsHeadMountedDisplayDevice = () => {
    return {
        type: UNSET_IS_HEAD_MOUNTED_DISPLAY_DEVICE,
    };
};

export const dispatchUnsetIsHeadMountedDisplayDevice = () => {
    store.dispatch(unsetIsHeadMountedDisplayDevice());
};

// UPDATE ACTIVE DEVICE ID

const updateActiveDeviceId = id => {
    return {
        type: UPDATE_ACTIVE_DEVICE_ID,
        payload: id,
    };
};

export const dispatchUpdateActiveDeviceId = id => {
    store.dispatch(updateActiveDeviceId(id));
};

// SHOW SESSION HANDOVER SUCCEEDED

const showSessionHandoverSuccess = () => {
    return {
        type: SHOW_SESSION_HANDOVER_SUCCESS,
    };
};

export const showSessionHandoverSuccessDispatch = () => {
    store.dispatch(showSessionHandoverSuccess());
};

// HIDE SESSION HANDOVER SUCCEEDED

const hideSessionTransfer = () => {
    return {
        type: HIDE_SESSION_HANDOVER_SUCCESS,
    };
};

export const hideSessionTransferDispatch = () => {
    store.dispatch(hideSessionTransfer());
};

// ACTIVATE SESSION HIJACKING

const activateSessionHijacking = () => {
    return {
        type: ACTIVATE_SESSION_HIJACKING,
    };
};

export const activateSessionHijackingDispatch = () => {
    store.dispatch(activateSessionHijacking());
};

// DEACTIVATE SESSION HIJACKING

const deactivateSessionHijacking = () => {
    return {
        type: DEACTIVATE_SESSION_HIJACKING,
    };
};

export const deactivateSessionHijackingDispatch = () => {
    store.dispatch(deactivateSessionHijacking());
};

// RESET

const resetSessionHandlingData = () => {
    return {
        type: RESET_SESSION_HANDOVER_DATA,
    };
};

export const dispatchResetSessionHandlingData = () => {
    store.dispatch(resetSessionHandlingData());
};

// ADD CHAT HISTORY

const addChatHistory = ({ activeDeviceId, dispatcherMessage }) => {
    return {
        type: ADD_CHAT_HISTORY,
        payload: {
            dispatcherMessage,
            activeDeviceId,
        },
    };
};

export const dispatchAddChatHistory = ({ activeDeviceId, dispatcherMessage }) => {
    store.dispatch(addChatHistory({ activeDeviceId, dispatcherMessage }));
};

// ADD GEO LOCATION

const addGeoLocation = ({ activeDeviceId, geolocation }) => {
    return {
        type: ADD_GEO_LOCATION,
        payload: {
            geolocation,
            activeDeviceId,
        },
    };
};

export const dispatchAddGeoLocation = ({ activeDeviceId, geolocation }) => {
    store.dispatch(addGeoLocation({ activeDeviceId, geolocation }));
};

// ADD ALL DEVICES

const addAllDevices = (devices) => {
    return {
        type: ADD_ALL_DEVICES,
        payload: devices
    };
};

export const dispatchAddAllDevices = devices => {
    store.dispatch(addAllDevices(devices));
};