import React from 'react';
import clsx from 'clsx';

import { connect } from 'react-redux';

import { store } from '../../store/DispatcherStore';

import OpenStreetmap from './OpenStreetmap';
import SystemPanel from './SystemPanel';
import GeoPanel from './GeoPanel';
import DispatcherNavbar from './DispatcherNavbar';
import GridBox from '../Globals/GridBox';
import WebRTCSwitcher from './WebRTCSwitcher';
import ChatViewDispatcher from '../Chat/ChatViewDispatcher';
import CameraChooser from './CameraChooser';
import SystemFooter from '../Globals/SystemFooter';

import './DispatcherDashboard.scss';
import { addLogDispatch } from '../../redux/actions/logs';
import {
    deactivateVideoDispatcherDispatch,
    deactivateChatDispatcherDispatch,
    deactivateGPSDispatch,
    deactivateSnapshotDispatch,
    disableSnapshotDispatch,
    disablePointerDispatch,
    deactivatePointerDispatcherDispatch,
    deactivateHDSendDispatch,
    deactivateDrawDispatcherDispatch,
    disableDrawDispatch,
} from '../../redux/actions/application';

import { DEBUG, DEFAULT_RECORDING_TTL } from '../../config';
import { replaceText, setDispatcherLanguage, setWhiteLabelLogo } from '../../helper/helper';
import { SMSGuide } from './SMSGuide';
import TimeoutOverlay from './TimeoutOverlay';
import BidiPanel from './BidiPanel';
import NotesPanel from './NotesPanel';
import { getCurrentDispatchCenterConfig, getImage } from '../../api/backendApi';
import { dispatchAddDispatchCenterTtl, dispatchAddVideoBackgroundImage } from '../../redux/actions/session';
import ScreensharePanel from './ScreensharePanel';
import {
    dispatchActivateFocusControls,
    dispatchResetFocusWindow,
    dispatchSetFocusWindowBidi,
    dispatchSetFocusWindowChat,
    dispatchSetFocusWindowExternalStream,
    dispatchSetFocusWindowLiveVideo,
    dispatchSetFocusWindowScreenshare,
} from '../../redux/actions/focus';
import { FOCUS_FEATURE_TYPE, Match, SessionHandling } from '../../types';
import { MediaGallery } from './MediaGallery';
import { InvitationPanel } from './InvitationPanel';
import ExternalStreamPanel from './ExternalStreamPanel';
import { VideoContainerDispatcher } from '../Video/VideoContainerDispatcher';
import SmartConnect from './SmartConnect';
import { dispatchResetSessionHandlingData } from '../../redux/actions/sessionHandling';

/**
 * Dispatcher Dashboard
 * main application hub for the dispatcher.
 * Provides access to all major functions for the dispatcher.
 *
 * @component OpenStreetmap - show the map
 * @component SystemPanel - shows the system infos of the bystander
 * @component GeoPanel - shows the geolocation infos of the bystander
 * @component DispatcherNavbar - Basic navigation bar with logo, state, session end
 * @component VideoContainerDispatcher - shows the video
 * @component Snapshots - take and view snapshots
 * @component Gridbox - Container open/close in the grid
 * @component WebRTCSwitcher - Switches for functions on the dashboard
 * @component ChatViewDispatcher - Shows the chat
 * @component CameraChooser - dropdown for the available cameras
 * @component SystemFooter - shows health, version and helpdesk
 */

interface DispatcherDashboardReduxProps {
    match?: Match;
    snapshotFeature: boolean;
    hdSendFeature: boolean;
    chatFeature: boolean;
    smsGuideFeature: boolean;
    pointerFeature: boolean;
    texts: {
        texts: [key: string];
    };
    osName: string;
    gpsIsActive: boolean;
    drawIsActive: boolean;
    videoIsActive: boolean;
    chatIsActive: boolean;
    hdSendIsActive: boolean;
    snapshotIsActive: boolean;
    pointerIsActive: boolean;
    drawFeature: boolean;
    audioStreamFeature: boolean;
    audioStreamIsActive: boolean;
    sharingFeature: boolean;
    bidiFeature: boolean;
    bidiIsActive: boolean;
    notesFeature: boolean;
    streamRecordingFeature: boolean;
    streamRecordingIsActive: boolean;
    screenShareFeature: boolean;
    screenshareIsActive: boolean;
    currentFocusFeature: string;
    focusControlsAreActive: boolean;
    inviteFeature: boolean;
    // TODO: create type for session
    session: any;
    fileShareFeature: boolean;
    // TODO: create type for uploadQueue
    uploadQueue: any;
    smartConnectFeature: boolean;
    smartConnectIsActive: boolean;
    externalStreamIsActive: boolean;
    sessionHandling: SessionHandling;
    // TODO: create type for invitedUsers
    invitedUsers: any;
}

interface DispatcherDashboardState {
    mounted: boolean;
}

type DispatcherDashboardProps = DispatcherDashboardReduxProps;

class DispatcherDashboard extends React.Component<DispatcherDashboardProps, DispatcherDashboardState> {
    private systemPanelRef: React.RefObject<HTMLDivElement>;
    private mapRef: React.RefObject<HTMLDivElement>;

    constructor(props: DispatcherDashboardProps) {
        super(props);
        this.state = {
            mounted: false,
        };

        this.systemPanelRef = React.createRef();
        this.mapRef = React.createRef();
    }

    initConnection() {
        store.initWebRTC(this.onInit);
    }

    onInit = async () => {
        if (!this.state.mounted) {
            if (this.props.videoIsActive) deactivateVideoDispatcherDispatch();
            if (this.props.chatIsActive) deactivateChatDispatcherDispatch();
            if (this.props.gpsIsActive) deactivateGPSDispatch();
            if (this.props.hdSendIsActive) deactivateHDSendDispatch();
            if (this.props.snapshotIsActive) deactivateSnapshotDispatch();
            if (this.props.snapshotFeature) disableSnapshotDispatch();
            if (this.props.pointerFeature) disablePointerDispatch();
            if (this.props.pointerIsActive) deactivatePointerDispatcherDispatch();
            if (this.props.drawIsActive) deactivateDrawDispatcherDispatch();
            if (this.props.drawFeature) disableDrawDispatch();
            setWhiteLabelLogo();
            setDispatcherLanguage();
            const videoBackgroundImage = await getImage({ type: 'base64', endpoint: process.env.REACT_APP_BACKGROUND_IMAGE_GET });
            if (videoBackgroundImage) {
                dispatchAddVideoBackgroundImage({ image: videoBackgroundImage });
            }
            const dispatchCenterConfig = await getCurrentDispatchCenterConfig();

            if (dispatchCenterConfig && dispatchCenterConfig.timeToLive !== null) {
                dispatchAddDispatchCenterTtl(dispatchCenterConfig.timeToLive);
            } else {
                dispatchAddDispatchCenterTtl(DEFAULT_RECORDING_TTL);
            }

            this.setState({
                mounted: true,
            });

            dispatchActivateFocusControls();
            dispatchResetSessionHandlingData();
        }
    };

    languageChange = languageLabel => {
        // TODO: add type
        (this.systemPanelRef as any).current.updateLanguage(languageLabel);
    };

    mapResize = isClosed => {
        if (!isClosed && this.mapRef.current) {
            // TODO: add type
            (this.mapRef as any).current.resize();
        }
    };

    _keys = {};

    addKey = e => {
        if (e.keyCode === 116 || e.keyCode === 82 || e.keyCode === 17 || e.keyCode === 91) {
            this._keys[e.keyCode] = true;

            // F5
            // CTRL + R
            // COMMAND + R
            if (this._keys[116] || (this._keys[82] && this._keys[17]) || (this._keys[82] && this._keys[91])) {
                e.preventDefault();
                e.stopPropagation();
                Object.keys(this._keys).forEach(key => {
                    this._keys[key] = false;
                });
                alert(replaceText(this.props.texts, 'system.alertRefresh'));
            }
        }
    };

    removeKey = e => {
        if (e.keyCode === 116 || e.keyCode === 82 || e.keyCode === 17 || e.keyCode === 91) {
            this._keys[e.keyCode] = false;
            if (DEBUG) addLogDispatch(['keys', this._keys]);
        }
    };

    setFocusOnNextFeatureInLine() {
        switch (true) {
            case this.props.videoIsActive:
                dispatchSetFocusWindowLiveVideo();
                store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.LIVE_VIDEO);
                break;
            case this.props.chatIsActive:
                dispatchSetFocusWindowChat(store.type);
                store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.CHAT);
                break;
            case this.props.bidiIsActive:
                dispatchSetFocusWindowBidi();
                store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.BIDI);
                break;
            case this.props.screenshareIsActive:
                dispatchSetFocusWindowScreenshare();
                store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.SCREEN_SHARE);
                break;
            case this.props.externalStreamIsActive:
                dispatchSetFocusWindowExternalStream();
                store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.EXTERNAL_STREAM);
                break;
            default:
                dispatchResetFocusWindow();
                store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.RESET_FOCUS);
                break;
        }
    }

    /* lifecycle */

    componentDidMount() {
        window.addEventListener('keydown', this.addKey);
        window.addEventListener('keyup', this.removeKey);
        store.getUrlParamsAndCreateSessionGroup().then(() => {
            this.initConnection();
        });
    }

    componentWillUnmount() {
        store.clearCallCallbacks();
        window.removeEventListener('keydown', this.addKey);
        window.removeEventListener('keyup', this.removeKey);
    }

    componentDidUpdate(prevProps) {
        if (
            (this.props.videoIsActive !== prevProps.videoIsActive &&
                !this.props.videoIsActive &&
                this.props.currentFocusFeature === FOCUS_FEATURE_TYPE.LIVE_VIDEO) ||
            (this.props.chatIsActive !== prevProps.chatIsActive && !this.props.chatIsActive && this.props.currentFocusFeature === FOCUS_FEATURE_TYPE.CHAT) ||
            (this.props.bidiIsActive !== prevProps.bidiIsActive && !this.props.bidiIsActive && this.props.currentFocusFeature === FOCUS_FEATURE_TYPE.BIDI) ||
            (this.props.screenshareIsActive !== prevProps.screenshareIsActive &&
                !this.props.screenshareIsActive &&
                this.props.currentFocusFeature === FOCUS_FEATURE_TYPE.SCREEN_SHARE)
        ) {
            this.setFocusOnNextFeatureInLine();
        }

        // only include relevant data
        if (this.props.session !== prevProps.session) {
            if (
                this.props.session.accuracy !== prevProps.session.accuracy ||
                this.props.session.browser !== prevProps.session.browser ||
                this.props.session.chatHistory.length !== prevProps.session.chatHistory.length ||
                this.props.session.language !== prevProps.session.language ||
                this.props.session.lat !== prevProps.session.lat ||
                this.props.session.long !== prevProps.session.long ||
                this.props.session.osName !== prevProps.session.osName ||
                this.props.session.osVersion !== prevProps.session.osVersion
            ) {
                store.sendSessionData();
                store.sendSessionHandlingData();
            }
        }

        if (this.props.sessionHandling !== prevProps.sessionHandling) {
            if (
                this.props.sessionHandling.activeDeviceId !== prevProps.sessionHandling.activeDeviceId ||
                this.props.sessionHandling.devices.length !== prevProps.sessionHandling.devices.length
            ) {
                store.sendSessionHandlingData();
            }
        }

        if (this.props.invitedUsers.length !== prevProps.invitedUsers.length) {
            store.sendSessionHandlingData();
        }

        if (this.props.currentFocusFeature !== prevProps.currentFocusFeature) {
            const message = {
                data: 'currentFocusFeature',
                currentFocusFeature: this.props.currentFocusFeature,
            };
            store.sendMessageToAllConferenceUsers(message);
        }

        if (this.props.audioStreamIsActive !== prevProps.audioStreamIsActive) {
            store.sendAudioActivationStatusFromStore();
        }
    }

    render() {
        const classes = clsx('r', {
            'r--chat': this.props.chatFeature,
        });

        return (
            <React.Fragment>
                <div className="dispatcherDashboard">
                    <DispatcherNavbar callerId={this.props.match.params.id} />

                    <div className="grid">
                        <div className={classes}>
                            <div className="c">
                                <GridBox isContainer={true} addClass="rtcSwitchBox" headline={replaceText(this.props.texts, 'info.function')}>
                                    <WebRTCSwitcher />
                                </GridBox>
                                <GridBox
                                    isClosed={true}
                                    isContainer={true}
                                    openOnFeatureActivation={this.props.videoIsActive}
                                    addClass="cameraChooser"
                                    headline={replaceText(this.props.texts, 'cameradropdown.title')}>
                                    <CameraChooser />
                                </GridBox>
                                {this.props.smartConnectFeature && (
                                    <GridBox
                                        isClosed={true}
                                        isContainer={true}
                                        openOnFeatureActivation={this.props.smartConnectIsActive}
                                        addClass="smartConnectBox"
                                        headline={replaceText(this.props.texts, 'smartConnectDropdown.title')}>
                                        <SmartConnect />
                                    </GridBox>
                                )}
                                {(this.props.inviteFeature || this.props.audioStreamFeature) && (
                                    <GridBox
                                        addClass="infoBox"
                                        isClosed={true}
                                        openOnFeatureActivation={this.props.audioStreamIsActive}
                                        isContainer={true}
                                        headline={replaceText(this.props.texts, 'invitation.title')}>
                                        <InvitationPanel />
                                    </GridBox>
                                )}
                                <GridBox addClass="infoBox" isContainer={true} headline={replaceText(this.props.texts, 'info.system')}>
                                    <SystemPanel />
                                </GridBox>
                                <GridBox
                                    addClass="infoBox"
                                    isClosed={true}
                                    openOnFeatureActivation={this.props.gpsIsActive}
                                    isContainer={true}
                                    headline={replaceText(this.props.texts, 'info.position')}>
                                    <GeoPanel />
                                </GridBox>
                            </div>
                            <div className="c">
                                <GridBox
                                    isContainer={true}
                                    isClosed={true}
                                    openOnFeatureActivation={this.props.gpsIsActive}
                                    addClass="mapBox"
                                    headline={replaceText(this.props.texts, 'info.map')}>
                                    <OpenStreetmap />
                                </GridBox>
                                <GridBox
                                    isContainer={true}
                                    alwaysOpened={this.props.drawIsActive || (this.props.videoIsActive && !this.props.focusControlsAreActive)}
                                    addClass="videoBox"
                                    isFocusable={this.props.videoIsActive || this.props.externalStreamIsActive}
                                    isInFocus={
                                        this.props.currentFocusFeature === FOCUS_FEATURE_TYPE.LIVE_VIDEO ||
                                        this.props.currentFocusFeature === FOCUS_FEATURE_TYPE.EXTERNAL_STREAM
                                    }
                                    changeFocus={() => {
                                        if (this.props.videoIsActive) {
                                            dispatchSetFocusWindowLiveVideo();
                                            store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.LIVE_VIDEO);
                                        }
                                        if (this.props.externalStreamIsActive) {
                                            dispatchSetFocusWindowExternalStream();
                                            store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.EXTERNAL_STREAM);
                                        }
                                    }}
                                    headline={replaceText(this.props.texts, 'info.video')}>
                                    <VideoContainerDispatcher />
                                    <ExternalStreamPanel isDispatcher={true} />
                                </GridBox>
                                {(this.props.snapshotFeature ||
                                    this.props.hdSendFeature ||
                                    this.props.streamRecordingFeature ||
                                    this.props.fileShareFeature) && (
                                    <GridBox
                                        isClosed={true}
                                        isContainer={true}
                                        openOnFeatureActivation={
                                            this.props.snapshotIsActive ||
                                            this.props.drawIsActive ||
                                            this.props.streamRecordingIsActive ||
                                            this.props.uploadQueue.length !== 0
                                        }
                                        addClass="mediaBox"
                                        headline={replaceText(this.props.texts, 'media.boxHeader')}>
                                        <MediaGallery />
                                    </GridBox>
                                )}
                            </div>
                            <div className="c">
                                {this.props.bidiFeature && (
                                    <GridBox
                                        isContainer={true}
                                        alwaysOpened={this.props.bidiIsActive && !this.props.focusControlsAreActive}
                                        addClass="bidiBox"
                                        isClosed={true}
                                        openOnFeatureActivation={this.props.bidiIsActive}
                                        isFocusable={this.props.bidiIsActive && !this.props.drawIsActive}
                                        isInFocus={this.props.currentFocusFeature === FOCUS_FEATURE_TYPE.BIDI}
                                        changeFocus={() => {
                                            dispatchSetFocusWindowBidi();
                                            store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.BIDI);
                                        }}
                                        headline={replaceText(this.props.texts, 'info.bidi')}>
                                        <BidiPanel />
                                    </GridBox>
                                )}
                                {this.props.notesFeature && (
                                    <GridBox isContainer={true} addClass="noteBox" isClosed={true} headline={replaceText(this.props.texts, 'info.notes')}>
                                        <NotesPanel />
                                    </GridBox>
                                )}
                                {this.props.screenShareFeature && (
                                    <GridBox
                                        isContainer={true}
                                        alwaysOpened={this.props.screenshareIsActive && !this.props.focusControlsAreActive}
                                        addClass="screenshareBox"
                                        isClosed={true}
                                        openOnFeatureActivation={this.props.screenshareIsActive}
                                        isFocusable={this.props.screenshareIsActive && !this.props.drawIsActive}
                                        isInFocus={this.props.currentFocusFeature === FOCUS_FEATURE_TYPE.SCREEN_SHARE}
                                        changeFocus={() => {
                                            dispatchSetFocusWindowScreenshare();
                                            store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.SCREEN_SHARE);
                                        }}
                                        headline={replaceText(this.props.texts, 'info.screenshare')}>
                                        <ScreensharePanel />
                                    </GridBox>
                                )}
                                {this.props.chatFeature && (
                                    <GridBox
                                        isContainer={true}
                                        alwaysOpened={this.props.chatIsActive && !this.props.focusControlsAreActive}
                                        addClass="chatBox"
                                        isFocusable={!this.props.drawIsActive}
                                        isInFocus={this.props.currentFocusFeature === FOCUS_FEATURE_TYPE.CHAT}
                                        changeFocus={() => {
                                            dispatchSetFocusWindowChat(store.type);
                                            store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.CHAT);
                                        }}
                                        headline={replaceText(this.props.texts, 'info.chat')}>
                                        <ChatViewDispatcher handleLanguageChange={this.languageChange} />
                                    </GridBox>
                                )}
                            </div>
                        </div>
                    </div>
                    <SystemFooter />
                    {this.props.smsGuideFeature && <SMSGuide />}
                    <TimeoutOverlay />
                    <div
                        id="incomingStreamToDispatcher"
                        style={{
                            display: 'none',
                            position: 'absolute',
                        }}></div>
                    {/* Hidden dummy stream canvas */}
                    <video
                        id="hiddenCanvas"
                        style={{
                            display: 'none',
                            position: 'absolute',
                        }}></video>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        snapshotFeature: state.features.snapshotFeature,
        hdSendFeature: state.features.hdSendFeature,
        chatFeature: state.features.chatFeature,
        texts: state.texts.texts,
        smsGuideFeature: state.features.smsGuideFeature,
        pointerFeature: state.features.pointerFeature,
        drawFeature: state.features.drawFeature,
        osName: state.session.osName,
        gpsIsActive: state.application.gpsIsActive,
        drawIsActive: state.application.drawIsActive,
        videoIsActive: state.application.videoIsActive,
        chatIsActive: state.application.chatIsActive,
        hdSendIsActive: state.application.hdSendIsActive,
        pointerIsActive: state.application.pointerIsActive,
        audioStreamIsActive: state.application.audioStreamIsActive,
        snapshotIsActive: state.application.snapshotIsActive,
        audioStreamFeature: state.features.audioStreamFeature,
        sharingFeature: state.features.sharingFeature,
        bidiFeature: state.features.bidiFeature,
        bidiIsActive: state.application.bidiIsActive,
        notesFeature: state.features.notesFeature,
        streamRecordingFeature: state.features.streamRecordingFeature,
        streamRecordingIsActive: state.application.streamRecordingIsActive,
        screenShareFeature: state.features.screenShareFeature,
        screenshareIsActive: state.application.screenshareIsActive,
        currentFocusFeature: state.focus.currentFocusFeature,
        focusControlsAreActive: state.focus.focusControlsAreActive,
        inviteFeature: state.features.inviteFeature,
        session: state.session,
        fileShareFeature: state.features.fileShareFeature,
        uploadQueue: state.files.uploadQueue,
        smartConnectFeature: state.features.smartConnectFeature,
        smartConnectIsActive: state.application.smartConnectIsActive,
        externalStreamIsActive: state.application.externalStreamIsActive,
        sessionHandling: state.sessionHandling,
        invitedUsers: state.invitedUsers,
    };
};

export default connect(mapStateToProps)(DispatcherDashboard);
