import {
    ADD_SESSION_DEVICE,
    REMOVE_SESSION_DEVICE,
    RESET_SESSION_HANDOVER_DATA,
    SET_IS_HEAD_MOUNTED_DISPLAY_DEVICE,
    UNSET_IS_HEAD_MOUNTED_DISPLAY_DEVICE,
    DEACTIVATE_SESSION_HANDOVER,
    ACTIVATE_SESSION_HANDOVER,
    UPDATE_ACTIVE_DEVICE_ID,
    SHOW_SESSION_HANDOVER_SUCCESS,
    HIDE_SESSION_HANDOVER_SUCCESS,
    ACTIVATE_SESSION_HIJACKING,
    DEACTIVATE_SESSION_HIJACKING,
    ADD_CHAT_HISTORY,
    REPLACE_CALLER_DEVICE,
    ADD_GEO_LOCATION,
    ADD_ALL_DEVICES,
} from '../actions/sessionHandling';

export const initialState = {
    sessionHandoverIsActive: false,
    sessionHandoverSuccessShown: false,
    sessionHijackingIsActive: false,
    isHeadMountedDisplayDevice: false,
    activeDeviceId: null,
    devices: [],
};

type SessionHandoverAction = {
    type: string;
    payload: any;
};

const sessionHandlingReducer = (state = initialState, action: SessionHandoverAction) => {
    switch (action.type) {
        case ADD_SESSION_DEVICE: {
            const newDevice = { contact: action.payload, chatHistory: [], geolocation: {} };
            state = { ...state, devices: [...state.devices, newDevice] };
            return state;
        }
        case REMOVE_SESSION_DEVICE: {
            const filteredDevices = state.devices.filter(device => device.contact.userData.id !== action.payload.id);
            return { ...state, devices: filteredDevices };
        }
        case REPLACE_CALLER_DEVICE: {
            const updatedDevices = state.devices.map(device => {
                if (device.contact.userData.id === action.payload.currentDevice.contact.userData.id) {
                    return { contact: action.payload.newDevice, chatHistory: device.chatHistory, geolocation: device.geolocation };
                }
                return device;
            });
            return { ...state, devices: updatedDevices };
        }
        case ACTIVATE_SESSION_HANDOVER: {
            return { ...state, sessionHandoverIsActive: true };
        }
        case DEACTIVATE_SESSION_HANDOVER: {
            return { ...state, sessionHandoverIsActive: false };
        }
        case SET_IS_HEAD_MOUNTED_DISPLAY_DEVICE: {
            return { ...state, isHeadMountedDisplayDevice: true };
        }
        case UNSET_IS_HEAD_MOUNTED_DISPLAY_DEVICE: {
            return { ...state, isHeadMountedDisplayDevice: false };
        }
        case UPDATE_ACTIVE_DEVICE_ID: {
            return { ...state, activeDeviceId: action.payload };
        }
        case SHOW_SESSION_HANDOVER_SUCCESS: {
            return { ...state, sessionHandoverSuccessShown: true };
        }

        case HIDE_SESSION_HANDOVER_SUCCESS: {
            return { ...state, sessionHandoverSuccessShown: false };
        }

        case ACTIVATE_SESSION_HIJACKING: {
            return { ...state, sessionHijackingIsActive: true };
        }

        case DEACTIVATE_SESSION_HIJACKING: {
            return { ...state, sessionHijackingIsActive: false };
        }

        case ADD_CHAT_HISTORY: {
            const activeDevice = state.devices.find(device => device.contact.userData.id === action.payload.activeDeviceId);
            const updatedActiveDevice = { ...activeDevice, chatHistory: [...activeDevice.chatHistory, action.payload.dispatcherMessage] };
            const updatedDevices = state.devices.map(device => (device.contact.userData.id === action.payload.activeDeviceId ? updatedActiveDevice : device));
            return {
                ...state,
                devices: updatedDevices,
            };
        }

        case ADD_GEO_LOCATION: {
            const activeDevice = state.devices.find(device => device.contact.userData.id === action.payload.activeDeviceId);
            const updatedActiveDevice = { ...activeDevice, geolocation: { ...action.payload.geolocation } };
            const updatedDevices = state.devices.map(device => (device.contact.userData.id === action.payload.activeDeviceId ? updatedActiveDevice : device));
            return {
                ...state,
                devices: updatedDevices,
            };
        }

        case ADD_ALL_DEVICES: {
            return { ...state, devices: action.payload }
        }

        case RESET_SESSION_HANDOVER_DATA: {
            return initialState;
        }
        default:
            return state;
    }
};

export default sessionHandlingReducer;
