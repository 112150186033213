import React, { PureComponent } from 'react';
// import clsx from 'clsx';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';

import { createGeoJSONCircle, CenterButton } from '../../helper/osmHelper';
import { store } from '../../store/DispatcherStore';

import ConnectionOverlay from '../Globals/ConnectionOverlay';
import NoMap from '../Icons/NoMap';

import 'mapbox-gl/dist/mapbox-gl.css';
import './OpenStreetmap.scss';
import { connect } from 'react-redux';
import { replaceText } from '../../helper/helper';
import { addSessionMapDispatch } from '../../redux/actions/session';
import clsx from 'clsx';
import MapIcon from '../Icons/MapIcon';

/**
 * OpenStreetmap
 * Contains the map and functions to use it.
 * Uses mapboxgl as the basic library.
 */
class NewOpenStreetmap extends PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            currentLong: 0,
            currentLat: 0,
            currentAcc: 0,
            map: null,
            mapActive: false,
            centerOnce: true,
            centerButton: null,
            currentGeoDeviceId: null,
        };

        if (store.connectedSession) {
            store.connectedSession.removeListener('contactMessage', this.handleContactMessage);
            store.connectedSession.on('contactMessage', this.handleContactMessage);
        }
    }

    handleContactMessage = e => {
        // Display message in UI
        const message = JSON.parse(e.content);
        const sender = e.sender;

        if (this.props.activeDeviceId === null || sender.userData.id === this.props.activeDeviceId) {
            if (message && message.data === 'coords') {
                if (this._isMounted && this.state.map) {
                    if (
                        message.latitude !== this.state.currentLat ||
                        message.longitude !== this.state.currentLong ||
                        message.accuracy !== this.state.currentAcc
                    ) {
                        this.setState(
                            {
                                currentLong: message.longitude,
                                currentLat: message.latitude,
                                currentAcc: message.accuracy,
                            },
                            this.updateMap
                        );
                    }
                }
            }
            if (message && (message.data === 'coordsError' || message.data === 'coordsNotAvail' || message.data === 'coordsPermissionDenied')) {
                this.setState({ mapActive: false, map: null });
            }
        }
    };

    resize = () => {
        if (this.state.mapActive && this.state.map) {
            this.state.map.resize();
        }
    };

    initMap = () => {
        if (this.state.mapActive && this.state.map === null) {
            const map = new mapboxgl.Map({
                container: 'myMap',
                style: 'https://maps.geoapify.com/v1/styles/osm-carto/style.json?apiKey=01bbc3e9a8204a7086d06e6eaba03331',
                maxZoom: 20,
                preserveDrawingBuffer: true,
            });

            const scale = new mapboxgl.ScaleControl({
                maxWidth: 150,
                unit: 'metric',
            });

            const nav = new mapboxgl.NavigationControl({
                showCompass: false,
            });

            const centerButton = new CenterButton(this.centerMap);

            map.addControl(nav, 'top-left');
            map.addControl(scale, 'bottom-left');
            map.addControl(centerButton, 'top-right');

            map.on('zoomend', () => {
                if (!this.props.geoMap) {
                    // TODO: remove all timeouts and use mapbox events if possible
                    setTimeout(() => {
                        addSessionMapDispatch({
                            map: map.getCanvas().toDataURL(),
                        });
                    }, 500);
                }
            });

            // TODO: remove all timeouts and use mapbox events if possible
            setTimeout(() => {
                this.setState({ map, centerButton });
            }, 500);
        }
    };

    updateMap = () => {
        if (this.state.mapActive && this.state.map) {
            if (this.state.map.isStyleLoaded()) {
                this.addAccuracy();
                this.addPosition();
                if (this.state.centerOnce) {
                    this.centerMap();
                    this.setState({ centerOnce: false });
                }
            }
        }
    };

    addAccuracy = () => {
        if (typeof this.state.map.getLayer('accuracy') !== 'undefined') this.state.map.removeLayer('accuracy');
        if (typeof this.state.map.getSource('accuracy') !== 'undefined') this.state.map.removeSource('accuracy');

        this.state.map.addSource('accuracy', createGeoJSONCircle([this.state.currentLong, this.state.currentLat], this.state.currentAcc));

        this.state.map.addLayer({
            id: 'accuracy',
            type: 'fill',
            source: 'accuracy',
            paint: {
                'fill-color': '#0073ff',
                'fill-opacity': 0.2,
            },
        });
    };

    centerMap = () => {
        if (this.state.map !== null) {
            // avoid flying animation before coordinates are initialized
            if (this.state.currentLong !== 0) {
                this.state.map.flyTo({
                    center: [this.state.currentLong, this.state.currentLat],
                    zoom: 17,
                });
            }
        }
    };

    addPosition = () => {
        if (typeof this.state.map.getLayer('user-coordinates') !== 'undefined') this.state.map.removeLayer('user-coordinates');
        if (typeof this.state.map.getSource('user-coordinates') !== 'undefined') this.state.map.removeSource('user-coordinates');

        this.state.map.addSource('user-coordinates', {
            type: 'geojson',
            data: {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [this.state.currentLong, this.state.currentLat],
                },
            },
        });

        this.state.map.addLayer({
            id: 'user-coordinates',
            source: 'user-coordinates',
            type: 'circle',
            paint: {
                'circle-color': '#0073ff',
            },
        });
    };

    changeButtonText = () => {
        if (this.state.centerButton) {
            this.state.centerButton.changeTextTo(replaceText(this.props.texts, 'map.reset'));
        }
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.gpsIsActive !== this.props.gpsIsActive && this.props.gpsIsActive) {
            this.setState({
                currentGeoDeviceId: this.props.activeDeviceId,
            });
            this.init(this.updateMapFromStore());
        }
        if (prevProps.boxIsClosed !== this.props.boxIsClosed && this.props.boxIsClosed === false) {
            this.resize();
        }
        if (prevProps.forceResize !== this.props.forceResize && this.props.forceResize === true) {
            this.resize();
        }
        // update the map if the position changes in the redux store
        // e.g. conference user gets update from the dispatcher side
        if (this.props.latitude !== prevProps.latitude || this.props.longitude !== prevProps.longitude || this.props.accuracy !== prevProps.accuracy) {
            this.updateMapFromStore();
        }

        // update map if active device gps coordinates change
        if (this.props.devices.length !== 0) {
            const activeDevice = this.props.devices.find(device => device.contact.userData.id === this.props.activeDeviceId);
            const prevActiveDevice = prevProps.devices.find(device => device.contact.userData.id === this.props.activeDeviceId);

            if (typeof activeDevice === undefined || typeof activeDevice.geolocation === undefined) {
                return;
            }

            if (activeDevice && activeDevice.geolocation && activeDevice.geolocation.longtitude) {
                this.updateMapFromStore();
            }

            if (prevActiveDevice) {
                if (prevActiveDevice.geolocation) {
                    if (prevActiveDevice.geolocation.longtitude) {
                        if (activeDevice.geolocation.longtitude !== prevActiveDevice.geolocation.longtitude) {
                            this.updateMapFromStore();
                        }
                    }
                }
            }
        }

        this.changeButtonText();
    }

    init = (cb = () => {}) => {
        // TODO: remove all timeouts and use mapbox events if possible
        window.setTimeout(() => {
            this.setState({ mapActive: this.props.gpsIsActive }, () => {
                this.initMap();
                this.resize();
                cb();
            });
        }, 100);
    };

    updateMapFromStore = () => {
        // type check for the position since the store gets initialized with strings
        if (typeof this.props.latitude !== 'number' || typeof this.props.longitude !== 'number' || typeof this.props.accuracy !== 'number') {
            return;
        }
        const activeDevice = this.props.devices.find(device => device.contact.userData.id === this.props.activeDeviceId);
        if (activeDevice && activeDevice.geolocation && Object.entries(activeDevice.geolocation).length !== 0) {
            this.setState(
                {
                    currentLong: activeDevice.geolocation.longtitude,
                    currentLat: activeDevice.geolocation.latitude,
                    currentAcc: activeDevice.geolocation.accuracy,
                },
                () => {
                    // we need a delay here, since the map is not fully initialized yet
                    // TODO: remove all timeouts and use mapbox events if possible
                    window.setTimeout(() => {
                        this.updateMap();
                    }, 550);
                }
            );
        }
    };

    render() {
        // if isActive is not set, the gpsIsActive prop is used
        const activeState = this.props.isActive === undefined ? this.props.gpsIsActive : this.props.isActive && this.props.gpsIsActive;
        const mapClasses = clsx('openStreetMap', {
            'openStreetMap--active': activeState,
        });

        const wrapperClasses = `openStreetMap__wrapper ${this.props.geoMap ? '' : 'mouseEventsDisabled'}`;

        return (
            <div className={mapClasses}>
                {!this.props.isHiddenConnectionOverlay && <ConnectionOverlay />}
                <div className={wrapperClasses}>
                    <div id="myMap" className="map"></div>
                    <div className="noMap">{this.props.useMapIcon ? <MapIcon /> : <NoMap />}</div>
                </div>
            </div>
        );
    }
}

// PropTypes for this Component
NewOpenStreetmap.propTypes = {
    gpsIsActive: PropTypes.bool,
    boxIsClosed: PropTypes.bool,
    texts: PropTypes.any,
    geoMap: PropTypes.any,
    isHiddenConnectionOverlay: PropTypes.bool,
    useMapIcon: PropTypes.bool,
    forceResize: PropTypes.bool,
    isActive: PropTypes.bool, // this overwrites the gpsIsActive prop when deciding if the map should be shown
    latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // latitude is initialized as an empty string in the store
    longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // longiture is initialized as an empty string in the store
    accuracy: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // accuracy is initialized as an empty string in the store
    activeDeviceId: PropTypes.string,
    devices: PropTypes.any,
};

const mapStateToProps = state => {
    return {
        gpsIsActive: state.application.gpsIsActive,
        texts: state.texts.texts,
        geoMap: state.session.geoMap,
        latitude: state.session.lat,
        longitude: state.session.long,
        accuracy: state.session.accuracy,
        activeDeviceId: state.sessionHandling.activeDeviceId,
        devices: state.sessionHandling.devices,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(NewOpenStreetmap);
