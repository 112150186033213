import { sendFileTransferInfo } from "../api/backendApi";
import { DEBUG, DISPLAY_ONLY_IN_SESSION, FOCUS_FEATURE_TIMEOUT, MAX_ALLOWED_CONFERENCE_USERS, SESSION_HIJACKING_EXPIRATION_TIMEOUT, VIDEO_AUTO_PLAY_REJECTION_TIMEOUT } from "../config";
import { createKpiLog, formatDataSize, getFileTypeCategory, isOnStartPage, runAfterTimeHasElapsed } from "../helper/helper";
import { getURLParams } from "../helper/rtcFlowHandling";
import { activateChatDispatcherDispatch, activateVideoDispatcherDispatch, deactivateAudioStreamDispatcherDispatch, deactivateDrawDispatcherDispatch, deactivateVideoDispatcherDispatch, dispatchCallerFileIsBusy, dispatchCallerFileTransferStarted, dispatchCallerPageLoaded, dispatchUnsetLiveVideoIsLoading, muteCallerMicrophoneDispatcherDispatch, setCallerTabInFocusDispatch, setCallerTabNotInFocusDispatch, unmuteCallerMicrophoneDispatcherDispatch } from "../redux/actions/application";
import { changeUsageDisclaimerStateCallerDispatch } from "../redux/actions/disclaimers";
import { addFileCallerDispatch } from "../redux/actions/files";
import { addLogDispatch } from "../redux/actions/logs";
import { addNotificationAndShowDispatch, hideAndRemoveNotificationsDispatch } from "../redux/actions/notifications";
import { dispatchAddPointsCaller, dispatchAllowPainting, dispatchDeletePaintCaller, dispatchDisallowPainting } from "../redux/actions/paint";
import { addSessionInfosDispatch, dispatchAddSessionFile, dispatchAllowPhotoPermisison, dispatchAllowStreamRecordingPermission, dispatchDisallowPhotoPermisison, dispatchDisallowStreamRecordingPermission, updatePhonenumberDispatch } from "../redux/actions/session";
import reduxStore from '../redux/store/index';
import { store as dispatcherStore } from "../store/DispatcherStore";
import parse from 'platform';
import { FOCUS_FEATURE_TYPE } from "../types";
import { dispatchSetFocusTriggeredByCaller, dispatchSetFocusWindowBidi, dispatchSetFocusWindowChat, dispatchSetFocusWindowExternalStream, dispatchSetFocusWindowLiveVideo, dispatchSetFocusWindowScreenshare, dispatchUnsetFocusChangedByCaller } from "../redux/actions/focus";
import { dispatchAddConnectionQuality, dispatchAddInvitedUser, dispatchSetAudioError, dispatchSetIsLeaving, dispatchToggleAudioMuted } from "../redux/actions/invitedUsers";
import { activateSessionHijackingDispatch, deactivateSessionHijackingDispatch, dispatchSetIsHeadMountedDisplayDevice } from "../redux/actions/sessionHandling";

export const handleContactMessageDispatcher = e => {
    //Display message in UI
    const sender = e.sender;
    const message = JSON.parse(e.content);
    const { callerId } = getURLParams();

    /**
     * INCOMING CALLER MESSAGES
     **/

    if (
        reduxStore.getState().sessionHandling.activeDeviceId === null ||
        sender.userData.id === reduxStore.getState().sessionHandling.activeDeviceId ||
        reduxStore.getState().sessionHandling.sessionHijackingIsActive ||
        reduxStore.getState().sessionHandling.sessionHandoverIsActive
    ) {
        if (message && message.data === 'system') {
            const infos = parse.parse(message.browserVersion1b);
            dispatcherStore.isAndroid = infos.os.toString().toLowerCase().indexOf('android') !== -1;
            dispatcherStore.isIOS = infos.os.toString().toLowerCase().indexOf('ios') !== -1;
            dispatcherStore.osMajorVersion = parseInt(infos.os.version.split('.')[0], 10);
            dispatcherStore.isFirefox = infos.name.toString().toLowerCase().indexOf('firefox') !== -1;

            addSessionInfosDispatch({
                osName: infos.os.family,
                osVersion: infos.os.version,
                browser: `${infos.name} - ${infos.version}`,
            });

            if (DEBUG)
                addLogDispatch([
                    `OS Infos - android: ${dispatcherStore.isAndroid} - iOS: ${dispatcherStore.isIOS} - version: ${dispatcherStore.osMajorVersion}`,
                ]);
        }
        if (message && message.data === 'heartbeat - pong') {
            dispatcherStore.pongMissed = 0;
            dispatcherStore.pongAccepted += 1;
        }

        if (message && message.data === 'hdFileCallerIsBusy') {
            dispatchCallerFileIsBusy();
        }

        if (message && message.data === 'hdFileTransferStarted') {
            dispatchCallerFileTransferStarted();
        }

        if (message && message.data === 'callerPaintPoints') {
            dispatchAddPointsCaller(message.points);
        }

        if (message && message.data === 'allowPainting') {
            message.state ? dispatchAllowPainting() : dispatchDisallowPainting();
        }

        if (message && message.data === 'deletePaintPointsCaller') {
            dispatchDeletePaintCaller();
        }

        if (message && message.data === 'declineDisclaimer') {
            changeUsageDisclaimerStateCallerDispatch('declined');
            hideAndRemoveNotificationsDispatch('pending');
            addNotificationAndShowDispatch('disclaimer.declined.caller', 'error', DISPLAY_ONLY_IN_SESSION);
            createKpiLog('stateCallerDisclaimer', 'declined');
        }

        if (message && message.data === 'acceptDisclaimer') {
            changeUsageDisclaimerStateCallerDispatch('accepted');
            hideAndRemoveNotificationsDispatch('pending');
            updatePhonenumberDispatch(dispatcherStore.phone);
            createKpiLog('stateCallerDisclaimer', 'accepted', { sessionId: dispatcherStore.sessionId });
        }

        if (message && message.data === 'photoPermission') {
            message.permission ? dispatchAllowPhotoPermisison() : dispatchDisallowPhotoPermisison();
        }

        if (message && message.data === 'streamRecordingPermission') {
            if (message.permission) {
                dispatchAllowStreamRecordingPermission();
            } else {
                dispatchDisallowStreamRecordingPermission();
            }
        }
        if (message && message.data === 'requestStreamRetry') {
            // Retry stream capture if promise doesn't resolve on caller side
            if (dispatcherStore.streamRetryRequests < 1) {
                setTimeout(() => {
                    activateVideoDispatcherDispatch();
                }, VIDEO_AUTO_PLAY_REJECTION_TIMEOUT);
                dispatcherStore.streamRetryRequests = 1;
            } else if (dispatcherStore.streamRetryRequests === 1) {
                addNotificationAndShowDispatch('camera.error', 'error', DISPLAY_ONLY_IN_SESSION);
                dispatchUnsetLiveVideoIsLoading();
            }
        }

        // If different camera id selected, reset stream retries
        if (message && message.data === 'checkIfStreamResolved') {
            if (dispatcherStore.streamRetryRequests === 1 && dispatcherStore.previousCameraId !== message.id) {
                dispatcherStore.streamRetryRequests = 0;
                dispatcherStore.previousCameraId = message.id;
            } else {
                dispatcherStore.previousCameraId = message.id;
            }
        }

        if (message && message.data === 'callerMutedMic') {
            muteCallerMicrophoneDispatcherDispatch();
        }

        if (message && message.data === 'callerUnmutedMic') {
            unmuteCallerMicrophoneDispatcherDispatch();
        }

        if (message && message.data === 'callerLoadedPage') {
            if (!isOnStartPage()) {
                dispatchCallerPageLoaded();
                const confirmationMessage = {
                    data: 'receivedCallerPageLoaded',
                };

                dispatcherStore.sendMessage(confirmationMessage);
            }

            if (reduxStore.getState().application.videoIsActive) {
                deactivateVideoDispatcherDispatch();
                if (reduxStore.getState().application.drawIsActive) {
                    deactivateDrawDispatcherDispatch();
                }
                addNotificationAndShowDispatch('VIDEO STREAM LOST', 'info'); // TODO translate properly
            }
            if (reduxStore.getState().application.chatIsActive) {
                activateChatDispatcherDispatch();
            } else {
                if (message.application.chatIsActive) {
                    dispatcherStore.toggleChat(false);
                }
            }
            if (reduxStore.getState().application.audioStreamIsActive) {
                deactivateAudioStreamDispatcherDispatch();
                addNotificationAndShowDispatch('info.aud_lst', 'info', DISPLAY_ONLY_IN_SESSION);
            }

            if (reduxStore.getState().features.streamRecordingFeature || reduxStore.getState().features.fileShareFeature) {
                const timeToLiveSettingMessage = {
                    data: 'timeToLiveSetting',
                    timeToLive: reduxStore.getState().session.timeToLive,
                };
                dispatcherStore.sendMessage(timeToLiveSettingMessage);
            }

            if (reduxStore.getState().features.fileShareFeature) {
                if (reduxStore.getState().files.dispatcherFiles.length !== 0 || reduxStore.getState().files.callerFiles.length !== 0) {
                    const uploadedFileContentsMessage = {
                        data: 'uploadedFiles',
                        uploadedFiles: reduxStore.getState().files,
                    };

                    dispatcherStore.sendMessage(uploadedFileContentsMessage);
                }
            }

            if (reduxStore.getState().application.externalStreamIsActive) {
                dispatcherStore.sendToggleExternalStreaming(true);
            }

            // if caller reloads their page, send current feature focus state
            dispatcherStore.sendCurrentFocusFeatureStatus();
        }

        if (message && message.data === 'disclaimerVisible') {
            setTimeout(() => {
                createKpiLog('displayCallerDisclaimer');
            }, 1000);
            addNotificationAndShowDispatch('disclaimer.waiting.caller', 'pending', DISPLAY_ONLY_IN_SESSION);
        }

        if (message && message.data === 'callerUploadedFile') {
            // send file transfer info to backend
            sendFileTransferInfo(message.fileInfo, reduxStore.getState().session.timeToLive);
            // format file size for redux store and display in chat history
            let file = message.fileInfo;
            const formattedFileSize = formatDataSize(file.size);
            file = { ...file, size: formattedFileSize };
            addFileCallerDispatch(file);
            dispatchAddSessionFile(file.name, getFileTypeCategory(file), file.time, file.url);
            createKpiLog('fileTransmissionCallerInfo', '', { 0: message.fileInfo.name, 1: message.fileInfo.size });
        }

        if (message && message.data === 'toggleFeatureFocus') {
            switch (true) {
                case message.state === FOCUS_FEATURE_TYPE.LIVE_VIDEO:
                    dispatchSetFocusWindowLiveVideo();
                    break;
                case message.state === FOCUS_FEATURE_TYPE.CHAT:
                    activateChatDispatcherDispatch();
                    dispatchSetFocusWindowChat(dispatcherStore.type);
                    break;
                case message.state === FOCUS_FEATURE_TYPE.BIDI:
                    dispatchSetFocusWindowBidi();
                    break;
                case message.state === FOCUS_FEATURE_TYPE.SCREEN_SHARE:
                    dispatchSetFocusWindowScreenshare();
                    break;
                case message.state === FOCUS_FEATURE_TYPE.EXTERNAL_STREAM:
                    dispatchSetFocusWindowExternalStream();
                    break;
                default:
                    break;
            }
            dispatchSetFocusTriggeredByCaller();
            runAfterTimeHasElapsed(dispatchUnsetFocusChangedByCaller, FOCUS_FEATURE_TIMEOUT);
        }

        if (message && message.data === 'invitedUserNetworkInfo') {
            dispatchAddConnectionQuality(message);
        }

        if (message && message.data === 'pageInFocus') {
            setCallerTabInFocusDispatch();
            clearTimeout(dispatcherStore.sessionHijackingActivationTimeout);
            if (reduxStore.getState().sessionHandling.sessionHijackingIsActive) deactivateSessionHijackingDispatch();
        }

        if (message && message.data === 'pageNotInFocus') {
            setCallerTabNotInFocusDispatch();
            dispatcherStore.sessionHijackingActivationTimeout = setTimeout(() => {
                activateSessionHijackingDispatch();
            }, SESSION_HIJACKING_EXPIRATION_TIMEOUT);
        }

        if (sender.userData.username === callerId && message && message.data === 'requestJoinPermission') {
            if (reduxStore.getState().sessionHandling.activeDeviceId === null || sender.userData.id === reduxStore.getState().sessionHandling.activeDeviceId) {
                dispatcherStore.sendJoinRequestGrantedToUser(sender);
            }
        }

        if (message && message.data === 'isHeadMountedDevice') {
            if (!reduxStore.getState().sessionHandling.isHeadMountedDisplayDevice) {
                dispatchSetIsHeadMountedDisplayDevice();
                addNotificationAndShowDispatch('session.device.isHmd', 'info', DISPLAY_ONLY_IN_SESSION);
                createKpiLog('hmdDeviceDetected');
            }
        }
    }

    /**
     * INCOMING INVITED USER MESSAGES
     **/

    if (sender && sender.userData && sender.userData.username && sender.userData.username !== callerId && message && message.data === 'requestJoinPermission') {
        if (reduxStore.getState().invitedUsers.length < MAX_ALLOWED_CONFERENCE_USERS) {
            dispatchAddInvitedUser(e.sender);

            const responseMessage = {
                data: 'joinRequestIsGranted',
            };

            dispatcherStore.sendMessageToAllConferenceUsers(responseMessage);
            dispatcherStore.sendSessionData();
            dispatcherStore.sendAudioActivationStatusFromStore();
            dispatcherStore.sendApplicationData();
            if (reduxStore.getState().paint.isPaintingAllowed) {
                dispatcherStore.sendAllowPaintingToConferenceUsers(true);
            }
        } else {
            const responseMessage = {
                data: 'joinRequestIsDeclined',
            };
            dispatcherStore.sendMessageToAllConferenceUsers(responseMessage);
        }
    }

    if (message && message.data === 'isLeaving') {
        reduxStore.getState().invitedUsers.map(user => {
            if (e.sender.userData.id === user.userData.id) {
                dispatchSetIsLeaving({ userId: user.userData.id });
            }
            return null;
        });
    }

    if (message && message.data === 'microphoneError') {
        dispatchSetAudioError(message.userId);
    }

    if (message && message.data === 'microphoneIsMuted') {
        dispatchToggleAudioMuted({ userId: message.userId, isAudioMuted: true });
    }
};
