import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { store } from '../../store/DispatcherStore';
import { oauthStore } from '../../store/OAuthStore';

import CloseSessionButton from './CloseSessionButton';
import SessionStatus from './SessionStatus';
import LanguageSelect from '../Globals/LanguageSelect';

import { getTitle, getLogo, replaceText, normalizePhone } from '../../helper/helper';

import './DispatcherNavbar.scss';
import { PhoneCountry } from '../Globals/PhoneCountry';
import { ResendSMS } from './ResendSMS';
import { OpenDevLink } from '../Icons/OpenDevLink';
import { CopyAddressButton } from './CopyAddressButton';
import Loading from '../Icons/LoadingSpinner';

/**
 * DispatcherNavbar
 * Navbar containing logo, connection state, session end button.
 *
 * @component SessionStatus - connection state
 * @component CloseSessionButton - closes the session
 */

class DispatcherNavbar extends PureComponent {
    getUsername() {
        return oauthStore.name;
    }

    getPhone() {
        return store.phone;
    }

    calculateLink(type) {
        if (type === 'caller') {
            return window.location.href.replace('disptchr', 'caller');
        }

        if (type === 'invitedUser') {
            return window.location.href.replace('disptchr', 'conference');
        }
    }

    render() {
        return (
            <div className="dispatcherNavbar navbar">
                <div>
                    <div className="logo">
                        <img src={getLogo()} alt="logo" />
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getTitle(),
                            }}></span>
                    </div>
                </div>
                <a
                    style={{
                        display: process.env.REACT_APP_SHOW_LINK === 'true' ? 'block' : 'none',
                    }}
                    id="callerLink"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.calculateLink('caller')}>
                    <div className="dev-caller-link">
                        <OpenDevLink />
                    </div>
                </a>
                <a
                    style={{
                        display: process.env.REACT_APP_SHOW_LINK === 'true' ? 'block' : 'none',
                    }}
                    id="invitedUsersLink"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.calculateLink('invitedUser')}>
                    <div className="dev-invited-users-link">
                        <OpenDevLink />
                    </div>
                </a>
                <div className="dispatcherNavbar__connected">
                    <div>{replaceText(this.props.texts, 'dispatcher.phoneConnected')}</div>
                    <div className="connectionInfoWrapper">
                        <strong>{normalizePhone(this.getPhone())}</strong>
                        <PhoneCountry phone={this.getPhone()} />
                    </div>
                </div>
                <ResendSMS />
                <CopyAddressButton userType="caller" />

                <div className="dispatcherNavbar__connectivity-container">
                    <SessionStatus store={store} />
                    {this.props.sessionHandoverIsActive ? (
                        <div className="dispatcherNavbar--device-transfer-status">
                            <span>{replaceText(this.props.texts, 'session.handover.status')}</span>
                            <Loading />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div>
                    <LanguageSelect />
                    <CloseSessionButton />
                </div>
            </div>
        );
    }
}

// PropTypes for this Component
DispatcherNavbar.propTypes = {
    callerId: PropTypes.string,
    texts: PropTypes.any,
    sessionHandoverIsActive: PropTypes.bool,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        sessionHandoverIsActive: state.sessionHandling.sessionHandoverIsActive,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(DispatcherNavbar);
