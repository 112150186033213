import store from '../store/index';
import { store as dispatcherStore } from '../../store/DispatcherStore';
// import reduxStore from '../redux/store';

import {
    ACTIVATE_CHAT,
    DEACTIVATE_CHAT,
    DISABLE_CHAT,
    ENABLE_CHAT,
    ACTIVATE_GPS,
    DISABLE_GPS,
    ENABLE_GPS,
    DEACTIVATE_GPS,
    ACTIVATE_VIDEO,
    DISABLE_VIDEO,
    ENABLE_VIDEO,
    DEACTIVATE_VIDEO,
    DEACTIVATE_SNAPSHOT,
    ACTIVATE_SNAPSHOT,
    ENABLE_SNAPSHOT,
    DISABLE_SNAPSHOT,
    DEACTIVATE_POINTER,
    ACTIVATE_POINTER,
    ENABLE_POINTER,
    DISABLE_POINTER,
    CALLER_CHAT_SHOW,
    CALLER_CHAT_HIDE,
    RESET_ALL,
    ADD_VIDEO_STREAM,
    REMOVE_VIDEO_STREAM,
    ADD_MEDIA_DEVICES,
    CHANGE_DEVICE_ID,
    REMOVE_DEVICE_ID,
    CHANGE_DEVICE_NAME,
    REMOVE_DEVICE_NAME,
    SHOW_REFRESH,
    PAUSE_SNAPSHOT,
    UNPAUSE_SNAPSHOT,
    ACTIVATE_HD_SEND,
    DEACTIVATE_HD_SEND,
    CALLER_FILE_TRANSFER_STARTED,
    CALLER_FILE_TRANSFER_ENDED,
    CALLER_FILE_IS_BUSY,
    CALLER_FILE_IS_NOT_BUSY,
    CALLER_PAGE_LOADED,
    CALLER_PAGE_NOT_LOADED,
    CALLER_END_PAGE_LOADED,
    CALLER_END_PAGE_NOT_LOADED,
    ACTIVATE_DRAW,
    DEACTIVATE_DRAW,
    ENABLE_DRAW,
    DISABLE_DRAW,
    ACTIVATE_CONFERENCING,
    DEACTIVATE_CONFERENCING,
    ENABLE_CONFERENCING,
    DISABLE_CONFERENCING,
    ACTIVATE_AUDIO_STREAM,
    DEACTIVATE_AUDIO_STREAM,
    ENABLE_AUDIO_STREAM,
    DISABLE_AUDIO_STREAM,
    UNMUTE_AUDIO,
    MUTE_AUDIO,
    UNMUTE_CALLER_MIC,
    MUTE_CALLER_MIC,
    ACTIVATE_BIDI,
    DEACTIVATE_BIDI,
    ENABLE_BIDI,
    DISABLE_BIDI,
    ACTIVATE_NOTES,
    DEACTIVATE_NOTES,
    ENABLE_STREAM_RECORDING,
    ACTIVATE_STREAM_RECORDING,
    DEACTIVATE_STREAM_RECORDING,
    DISABLED_STREAM_RECORDING,
    START_STREAM_RECORDING,
    STOP_STREAM_RECORDING,
    ACTIVATE_PHOTO_AUTO_DOWNLOAD,
    DEACTIVATE_PHOTO_AUTO_DOWNLOAD,
    SET_PASSWORD_CHANGE_SENT,
    ACTIVATE_BIDI_BLUR,
    DEACTIVATE_BIDI_BLUR,
    ACTIVATE_BIDI_BACKGROUND,
    DEACTIVATE_BIDI_BACKGROUND,
    ACTIVATE_WEB_RTC,
    DEACTIVATE_WEB_RTC,
    ENABLE_SCREEN_SHARE,
    DISABLE_SCREEN_SHARE,
    ACTIVATE_SCREEN_SHARE,
    DEACTIVATE_SCREEN_SHARE,
    OPEN_INVITATION_PANEL,
    CLOSE_INVITATION_PANEL,
    SET_LIVE_VIDEO_IS_LOADING,
    UNSET_LIVE_VIDEO_IS_LOADING,
    ACTIVATE_CONFERENCE_CHAT,
    DEACTIVATE_CONFERENCE_CHAT,
    ACTIVATE_EXTERNAL_STREAM,
    DEACTIVATE_EXTERNAL_STREAM,
    ACTIVATE_SMART_CONNECT,
    DEACTIVATE_SMART_CONNECT,
    CALLER_TAB_IN_FOCUS,
    CALLER_TAB_NOT_IN_FOCUS,
} from './actionTypes';
import { addNotificationAndShowDispatch } from './notifications';
import { DISPLAY_ONLY_IN_SESSION, FILE_SELECT_BUSY_CALLER_HINT } from '../../config';
import { dispatchResetPaint } from './paint';
import {
    readdAllStreamsToContainerCaller,
    readdAllStreamsToContainerDispatcher,
    removeAllStreamsFromContainerCaller,
    removeAllStreamsFromContainerDispatcher,
} from '../../helper/rtcFlowHandling';
import { createKpiLog, isOnStartPage } from '../../helper/helper';
import { dispatchRemoveCallerStream, dispatchRemoveDispatcherStream } from './stream';

/**
 * All application actions
 *
 * Actions always return the type
 * Dispatch always dispatches the action
 */

const activateChat = () => {
    return {
        type: ACTIVATE_CHAT,
    };
};

// CHAT

export const activateChatDispatcherDispatch = () => {
    dispatcherStore.toggleChat(true);

    createKpiLog('stateChatFeature', 'activated');

    store.dispatch(activateChat());
};

export const activateChatCallerDispatch = () => {
    store.dispatch(activateChat());
};

const deactivateChat = () => {
    return {
        type: DEACTIVATE_CHAT,
    };
};

export const deactivateChatDispatcherDispatch = () => {
    dispatcherStore.toggleChat(false);

    createKpiLog('stateChatFeature', 'deactivated');
    store.dispatch(deactivateChat());
};

export const deactivateChatCallerDispatch = () => {
    store.dispatch(deactivateChat());
};

const enableChat = () => {
    return {
        type: ENABLE_CHAT,
    };
};

export const enableChatDispatch = () => {
    store.dispatch(enableChat());
};

const disableChat = () => {
    return {
        type: DISABLE_CHAT,
    };
};

export const disableChatDispatch = () => {
    store.dispatch(disableChat());
};

// GPS

const activateGPS = () => {
    return {
        type: ACTIVATE_GPS,
    };
};

export const activateGPSDispatch = () => {
    dispatcherStore.toggleGPS(true);

    createKpiLog('stateGpsFeature', 'activated');

    store.dispatch(activateGPS());
};

const deactivateGPS = () => {
    return {
        type: DEACTIVATE_GPS,
    };
};

export const deactivateGPSDispatch = () => {
    dispatcherStore.toggleGPS(false);

    createKpiLog('stateGpsFeature', 'deactivated');

    store.dispatch(deactivateGPS());
};

const enableGPS = () => {
    return {
        type: ENABLE_GPS,
    };
};

export const enableGPSDispatch = () => {
    store.dispatch(enableGPS());
};

const disableGPS = () => {
    return {
        type: DISABLE_GPS,
    };
};

export const disableGPSDispatch = () => {
    store.dispatch(disableGPS());
};

// CALLER AUDIO

const activateAudioStream = () => {
    return {
        type: ACTIVATE_AUDIO_STREAM,
    };
};

const deactivateAudioStream = () => {
    return {
        type: DEACTIVATE_AUDIO_STREAM,
    };
};

/****/

export const activateAudioStreamDispatcherDispatch = () => {
    dispatcherStore.toggleAudioStream(true);

    createKpiLog('stateAudioFeature', 'activated');

    store.dispatch(activateAudioStream());
};

export const deactivateAudioStreamDispatcherDispatch = () => {
    dispatcherStore.toggleAudioStream(false);

    createKpiLog('stateAudioFeature', 'deactivated');

    store.dispatch(deactivateAudioStream());
};

/****/

export const activateAudioStreamCallerDispatch = () => {
    store.dispatch(activateAudioStream());
};

export const deactivateAudioStreamCallerDispatch = () => {
    store.dispatch(deactivateAudioStream());
};

/****/

const enableAudioStream = () => {
    return {
        type: ENABLE_AUDIO_STREAM,
    };
};

export const enableAudioStreamDispatch = () => {
    store.dispatch(enableAudioStream());
};

const disableAudioStream = () => {
    return {
        type: DISABLE_AUDIO_STREAM,
    };
};

export const disableAudioStreamDispatch = () => {
    store.dispatch(disableAudioStream());
};

// AUDIO MUTING DISPATCHER

const unmuteAudio = () => {
    return {
        type: UNMUTE_AUDIO,
    };
};

export const unmuteAudioDispatch = () => {
    createKpiLog('stateAudio', 'unmuted');

    readdAllStreamsToContainerDispatcher();
    store.dispatch(unmuteAudio());
};

const muteAudio = () => {
    return {
        type: MUTE_AUDIO,
    };
};

export const muteAudioDispatch = () => {
    if (!isOnStartPage()) createKpiLog('stateAudioVolume', 'muted');

    removeAllStreamsFromContainerDispatcher();
    store.dispatch(muteAudio());
};

// AUDIO MUTING CALLER

const unmuteAudioCaller = () => {
    return {
        type: UNMUTE_AUDIO,
    };
};

export const unmuteAudioCallerDispatch = () => {
    readdAllStreamsToContainerCaller();
    store.dispatch(unmuteAudioCaller());
};

const muteAudioCaller = () => {
    return {
        type: MUTE_AUDIO,
    };
};

export const muteAudioCallerDispatch = () => {
    removeAllStreamsFromContainerCaller();
    store.dispatch(muteAudioCaller());
};

// DISPATCHER - MUTING CALLER MICROPHONE

const unmuteCallerMicrophone = () => {
    return {
        type: UNMUTE_CALLER_MIC,
    };
};

export const unmuteCallerMicrophoneDispatcherDispatch = () => {
    store.dispatch(unmuteCallerMicrophone());
};

const muteCallerMicrophone = () => {
    return {
        type: MUTE_CALLER_MIC,
    };
};

export const muteCallerMicrophoneDispatcherDispatch = () => {
    store.dispatch(muteCallerMicrophone());
};

// VIDEO

const activateVideo = () => {
    return {
        type: ACTIVATE_VIDEO,
    };
};

export const activateVideoDispatcherDispatch = () => {
    dispatcherStore.toggleVideo(true);
    store.dispatch(activateVideo());
};

export const activateVideoCallerDispatch = () => {
    store.dispatch(activateVideo());
};

const deactivateVideo = () => {
    return {
        type: DEACTIVATE_VIDEO,
    };
};

export const deactivateVideoDispatcherDispatch = () => {
    dispatcherStore.toggleVideo(false);
    dispatchRemoveCallerStream();
    dispatcherStore.streamRetryRequests = 0;
    createKpiLog('stateLiveVideoFeature', 'deactivated');

    store.dispatch(deactivateVideo());
};

export const deactivateVideoCallerDispatch = () => {
    store.dispatch(deactivateVideo());
};

const enableVideo = () => {
    return {
        type: ENABLE_VIDEO,
    };
};

export const enableVideoDispatch = () => {
    store.dispatch(enableVideo());
};

const disableVideo = () => {
    return {
        type: DISABLE_VIDEO,
    };
};

export const disableVideoDispatch = () => {
    store.dispatch(disableVideo());
};

// SNAPSHOT

const activateSnapshot = () => {
    return {
        type: ACTIVATE_SNAPSHOT,
    };
};

export const activateSnapshotDispatch = () => {
    dispatcherStore.toggleSnapshot(true);
    createKpiLog('stateScreenshotFeature', 'activated');

    store.dispatch(activateSnapshot());
};

const deactivateSnapshot = () => {
    return {
        type: DEACTIVATE_SNAPSHOT,
    };
};

export const deactivateSnapshotDispatch = () => {
    dispatcherStore.toggleSnapshot(false);
    createKpiLog('stateScreenshotFeature', 'deactivated');

    store.dispatch(deactivateSnapshot());
};

const enableSnapshot = () => {
    return {
        type: ENABLE_SNAPSHOT,
    };
};

export const enableSnapshotDispatch = () => {
    store.dispatch(enableSnapshot());
};

const disableSnapshot = () => {
    return {
        type: DISABLE_SNAPSHOT,
    };
};

export const disableSnapshotDispatch = () => {
    store.dispatch(disableSnapshot());
};

const unpauseSnapshot = () => {
    return {
        type: UNPAUSE_SNAPSHOT,
    };
};

export const unpauseSnapshotDispatch = () => {
    store.dispatch(unpauseSnapshot());
};

const pauseSnapshot = () => {
    return {
        type: PAUSE_SNAPSHOT,
    };
};

export const pauseSnapshotDispatch = () => {
    store.dispatch(pauseSnapshot());
};

// POINTER

const activatePointer = () => {
    return {
        type: ACTIVATE_POINTER,
    };
};

export const activatePointerDispatcherDispatch = () => {
    dispatcherStore.togglePointer(true);
    createKpiLog('statePointerFeature', 'activated');

    store.dispatch(activatePointer());
};

export const activatePointerCallerDispatch = () => {
    store.dispatch(activatePointer());
};

const deactivatePointer = () => {
    return {
        type: DEACTIVATE_POINTER,
    };
};

export const deactivatePointerDispatcherDispatch = () => {
    dispatcherStore.togglePointer(false);
    createKpiLog('statePointerFeature', 'deactivated');

    store.dispatch(deactivatePointer());
};

export const deactivatePointerCallerDispatch = () => {
    store.dispatch(deactivatePointer());
};

const enablePointer = () => {
    return {
        type: ENABLE_POINTER,
    };
};

export const enablePointerDispatch = () => {
    store.dispatch(enablePointer());
};

const disablePointer = () => {
    return {
        type: DISABLE_POINTER,
    };
};

export const disablePointerDispatch = () => {
    store.dispatch(disablePointer());
};

// CALLER CHAT
const showChat = () => {
    return {
        type: CALLER_CHAT_SHOW,
    };
};

export const showChatDispatch = () => {
    store.dispatch(showChat());
};

const hideChat = () => {
    return {
        type: CALLER_CHAT_HIDE,
    };
};

export const hideChatDispatch = () => {
    store.dispatch(hideChat());
};

// MIXED
export const activateCallerChat = () => {
    activateChatCallerDispatch();
    showChatDispatch();
};

export const deactivateCallerChat = () => {
    deactivateChatCallerDispatch();
    hideChatDispatch();
};

// RESET ALL
const resetAll = () => {
    return {
        type: RESET_ALL,
    };
};

export const resetAllDispatch = () => {
    store.dispatch(resetAll());
};

// DISPATCHER VIDEO STREAM
const addVideoStream = () => {
    return {
        type: ADD_VIDEO_STREAM,
    };
};

export const addVideoStreamDispatch = () => {
    store.dispatch(addVideoStream());
};

const removeVideoStream = () => {
    return {
        type: REMOVE_VIDEO_STREAM,
    };
};

export const removeVideoStreamDispatch = () => {
    store.dispatch(removeVideoStream());
};

// MEDIA DEVICES

const addMediaDevices = devices => {
    return {
        type: ADD_MEDIA_DEVICES,
        devices,
    };
};

export const addMediaDevicesDispatch = devices => {
    store.dispatch(addMediaDevices(devices));
};

const changeDeviceId = deviceId => {
    return {
        type: CHANGE_DEVICE_ID,
        deviceId,
    };
};

export const changeDeviceIdDispatch = deviceId => {
    store.dispatch(changeDeviceId(deviceId));
};

const removeDeviceId = () => {
    return {
        type: REMOVE_DEVICE_ID,
    };
};

export const removeDeviceIdDispatch = () => {
    store.dispatch(removeDeviceId());
};

const changeDeviceName = deviceName => {
    return {
        type: CHANGE_DEVICE_NAME,
        deviceName,
    };
};

export const changeDeviceNameDispatch = deviceName => {
    store.dispatch(changeDeviceName(deviceName));
};

const removeDeviceName = () => {
    return {
        type: REMOVE_DEVICE_NAME,
    };
};

export const removeDeviceNameDispatch = () => {
    store.dispatch(removeDeviceName());
};

export const showRefresh = () => {
    return {
        type: SHOW_REFRESH,
    };
};

export const showRefreshDispatch = () => {
    store.dispatch(showRefresh());
};

// HD SEND
const activateHDSend = () => {
    return {
        type: ACTIVATE_HD_SEND,
    };
};

export const activateHDSendDispatch = () => {
    dispatcherStore.toggleHDSend(true);
    createKpiLog('infoPhotoRequest');
    store.dispatch(activateHDSend());
};

export const activateHDSendCallerDispatch = () => {
    store.dispatch(activateHDSend());
};

const deactivateHDSend = () => {
    dispatcherStore.toggleHDSend(false);
    return {
        type: DEACTIVATE_HD_SEND,
    };
};

export const deactivateHDSendDispatch = () => {
    store.dispatch(deactivateHDSend());
};

export const deactivateHDSendCallerDispatch = () => {
    store.dispatch(deactivateHDSend());
};

// CALLER FILE SELECT
let fileSelectTimeout;

const callerFileIsBusy = () => {
    return { type: CALLER_FILE_IS_BUSY };
};

export const dispatchCallerFileIsBusy = () => {
    store.dispatch(callerFileIsBusy());

    // change status to close automatically after x seconds
    clearTimeout(fileSelectTimeout);
    fileSelectTimeout = setTimeout(() => {
        dispatchCallerFileIsNotBusy();
        createKpiLog('infoPhotoUploadTimeout');
    }, FILE_SELECT_BUSY_CALLER_HINT);
};

const callerFileIsNotBusy = () => {
    return { type: CALLER_FILE_IS_NOT_BUSY };
};

export const dispatchCallerFileIsNotBusy = () => {
    clearTimeout(fileSelectTimeout);
    store.dispatch(callerFileIsNotBusy());
};

const callerFileTransferStarted = () => {
    return { type: CALLER_FILE_TRANSFER_STARTED };
};

export const dispatchCallerFileTransferStarted = () => {
    store.dispatch(callerFileTransferStarted());
};

const callerFileTransferEnded = () => {
    return { type: CALLER_FILE_TRANSFER_ENDED };
};

export const dispatchCallerFileTransferEnded = (timestamp, filetype) => {
    store.dispatch(callerFileTransferEnded());
    dispatchCallerFileIsNotBusy();
    deactivateHDSendDispatch();

    // runs only on dispatcher side
    if (timestamp) {
        addNotificationAndShowDispatch('hdsend.complete', 'info', DISPLAY_ONLY_IN_SESSION);
        let fileExtension = 'png';
        if (filetype.indexOf('jpeg') !== -1) {
            fileExtension = 'jpeg';
        }

        const filename = 'image_' + dispatcherStore.userId + '_' + dispatcherStore.bystanderToken + '_' + timestamp + '.' + fileExtension;

        const additionalStates = {
            0: filename,
        };

        createKpiLog('infoPhotoUploadSuccess', '', additionalStates);
    }
};

// DRAW

const activateDraw = () => {
    return {
        type: ACTIVATE_DRAW,
    };
};

export const activateDrawDispatcherDispatch = () => {
    dispatcherStore.toggleDraw(true);

    createKpiLog('stateDrawFeature', 'activated');

    store.dispatch(activateDraw());
};

export const activateDrawCallerDispatch = () => {
    store.dispatch(activateDraw());
};

const deactivateDraw = () => {
    dispatcherStore.toggleDraw(false);
    return {
        type: DEACTIVATE_DRAW,
    };
};

export const deactivateDrawDispatcherDispatch = () => {
    createKpiLog('stateDrawFeature', 'deactivated');

    dispatchResetPaint();
    store.dispatch(deactivateDraw());
};

export const deactivateDrawCallerDispatch = () => {
    store.dispatch(deactivateDraw());
};

const enableDraw = () => {
    return {
        type: ENABLE_DRAW,
    };
};

export const enableDrawDispatch = () => {
    store.dispatch(enableDraw());
};

const disableDraw = () => {
    return {
        type: DISABLE_DRAW,
    };
};

export const disableDrawDispatch = () => {
    store.dispatch(disableDraw());
};

// CONFERENCING

const activateConferencing = () => {
    return {
        type: ACTIVATE_CONFERENCING,
    };
};

export const activateConferencingDispatch = () => {
    dispatcherStore.handleDispatcherAudioStream(true);
    createKpiLog('stateShareFeature', 'activated');

    store.dispatch(activateConferencing());
};

const deactivateConferencing = () => {
    return {
        type: DEACTIVATE_CONFERENCING,
    };
};

export const deactivateConferencingDispatch = () => {
    createKpiLog('stateShareFeature', 'deactivated');

    store.dispatch(deactivateConferencing());
};

const enableConferencing = () => {
    return {
        type: ENABLE_CONFERENCING,
    };
};

export const enableConferencingDispatch = () => {
    store.dispatch(enableConferencing());
};

const disableConferencing = () => {
    return {
        type: DISABLE_CONFERENCING,
    };
};

export const disableConferencingDispatch = () => {
    store.dispatch(disableConferencing());
};

// Caller page loading states

const callerPageLoaded = () => {
    return { type: CALLER_PAGE_LOADED };
};

export const dispatchCallerPageLoaded = () => {
    store.dispatch(callerPageLoaded());
};

const callerPageNotLoaded = () => {
    return { type: CALLER_PAGE_NOT_LOADED };
};

export const dispatchCallerPageNotLoaded = () => {
    store.dispatch(callerPageNotLoaded());
};

const callerEndPageLoaded = () => {
    return { type: CALLER_END_PAGE_LOADED };
};

export const dispatchCallerEndPageLoaded = () => {
    store.dispatch(callerEndPageLoaded());
};

const callerEndPageNotLoaded = () => {
    return { type: CALLER_END_PAGE_NOT_LOADED };
};

export const dispatchCallerEndPageNotLoaded = () => {
    store.dispatch(callerEndPageNotLoaded());
};

// BIDI

const activateBidi = () => {
    return {
        type: ACTIVATE_BIDI,
    };
};

export const activateBidiDispatch = () => {
    createKpiLog('stateBidiFeature', 'activated');

    store.dispatch(activateBidi());
};

const deactivateBidi = () => {
    return {
        type: DEACTIVATE_BIDI,
    };
};

export const deactivateBidiDispatch = async () => {
    await dispatcherStore.stopBidi();
    createKpiLog('stateBidiFeature', 'deactivated');

    store.dispatch(deactivateBidi());
};

const enableBidi = () => {
    return {
        type: ENABLE_BIDI,
    };
};

export const enableBidiDispatch = () => {
    store.dispatch(enableBidi());
};

const disableBidi = () => {
    return {
        type: DISABLE_BIDI,
    };
};

export const disableBidiDispatch = () => {
    store.dispatch(disableBidi());
};

const activateBidiBlur = () => {
    return {
        type: ACTIVATE_BIDI_BLUR,
    };
};

export const activateBidiBlurDispatch = () => {
    store.dispatch(activateBidiBlur());
};

const deactivateBidiBlur = () => {
    return {
        type: DEACTIVATE_BIDI_BLUR,
    };
};

export const deactivateBidiBlurDispatch = async () => {
    await dispatcherStore.stopBidi();
    createKpiLog('stateBidiFeature', 'deactivated');

    store.dispatch(deactivateBidiBlur());
};

const activateBidiBackground = () => {
    return {
        type: ACTIVATE_BIDI_BACKGROUND,
    };
};

export const activateBidiBackgroundDispatch = () => {
    store.dispatch(activateBidiBackground());
};

const deactivateBidiBackground = () => {
    return {
        type: DEACTIVATE_BIDI_BACKGROUND,
    };
};

export const deactivateBidiBackgroundDispatch = async () => {
    await dispatcherStore.stopBidi();

    store.dispatch(deactivateBidiBackground());
};


// BIDI - Caller / Conference

const activateBidiOther = () => {
    return {
        type: ACTIVATE_BIDI,
    };
};

export const activateBidiOtherDispatch = () => {
    store.dispatch(activateBidiOther());
};

const deactivateBidiOther = () => {
    return {
        type: DEACTIVATE_BIDI,
    };
};

export const deactivateBidiOtherDispatch = () => {
    store.dispatch(deactivateBidiOther());
};

// NOTES

const activateNotes = () => {
    return {
        type: ACTIVATE_NOTES,
    };
};

export const activateNotesDispatch = () => {
    createKpiLog('stateNotesFeature');

    store.dispatch(activateNotes());
};

const deactivateNotes = () => {
    return {
        type: DEACTIVATE_NOTES,
    };
};

export const deactivateNotesDispatch = async () => {
    createKpiLog('stateNotesFeature', 'activated');

    store.dispatch(deactivateNotes());
};

// STREAM RECORDING

const enableStreamRecording = () => {
    return {
        type: ENABLE_STREAM_RECORDING,
    };
};

export const enableStreamRecordingDispatch = () => {
    store.dispatch(enableStreamRecording());
};

const disableStreamRecording = () => {
    return {
        type: DISABLED_STREAM_RECORDING,
    };
};

export const disableStreamRecordingDispatch = () => {
    store.dispatch(disableStreamRecording());
};

const activateStreamRecording = () => {
    return {
        type: ACTIVATE_STREAM_RECORDING,
    };
};

export const activateStreamRecordingDispatch = () => {
    createKpiLog('stateStreamRecording', 'activated');
    store.dispatch(activateStreamRecording());
};

const deactivateStreamRecording = () => {
    return {
        type: DEACTIVATE_STREAM_RECORDING,
    };
};

export const deactivateStreamRecordingDispatch = () => {
    createKpiLog('stateStreamRecording', 'deactivated');
    dispatcherStore.stopConversationRecording();

    store.dispatch(deactivateStreamRecording());
};

export const startStreamRecording = () => {
    return {
        type: START_STREAM_RECORDING,
    };
};

export const dispatchStartStreamRecording = () => {
    store.dispatch(startStreamRecording());
};

export const stopStreamRecording = () => {
    return {
        type: STOP_STREAM_RECORDING,
    };
};

export const dispatchStopStreamRecording = () => {
    store.dispatch(stopStreamRecording());
};

export const activateWebRtc = () => {
    return {
        type: ACTIVATE_WEB_RTC,
    };
};

export const dispatchActivateWebRtc = () => {
    store.dispatch(activateWebRtc());
};

export const deactivateWebRtc = () => {
    return {
        type: DEACTIVATE_WEB_RTC,
    };
};

export const dispatchDeactivateWebRtc = () => {
    store.dispatch(deactivateWebRtc());
};

// DISPATCHER MEDIA TTL SETTING

export const activatePhotoAutoDownload = () => {
    return {
        type: ACTIVATE_PHOTO_AUTO_DOWNLOAD,
    };
};

export const dispatchActivatePhotoAutoDownload = () => {
    store.dispatch(activatePhotoAutoDownload());
};

export const deactivatePhotoAutoDownload = () => {
    return {
        type: DEACTIVATE_PHOTO_AUTO_DOWNLOAD,
    };
};

export const dispatchDeactivatePhotoAutoDownload = () => {
    store.dispatch(deactivatePhotoAutoDownload());
};

export const passwordChangeSent = () => {
    return {
        type: SET_PASSWORD_CHANGE_SENT,
    };
};

export const dispatchPasswordChangeSent = () => {
    store.dispatch(passwordChangeSent());
};

// SCREEN SHARE

const enableScreenshare = () => {
    return {
        type: ENABLE_SCREEN_SHARE,
    };
};

export const enableScreenshareDispatch = () => {
    store.dispatch(enableScreenshare());
};

const disableScreenshare = () => {
    return {
        type: DISABLE_SCREEN_SHARE,
    };
};

export const disableScreenshareDispatch = () => {
    store.dispatch(disableScreenshare());
};

const activateScreenshare = () => {
    return {
        type: ACTIVATE_SCREEN_SHARE,
    };
};

export const activateScreenshareDispatch = async userType => {
    if (userType && userType === 'dispatcher') {
        await dispatcherStore.startScreenshare();
        createKpiLog('stateScreenshareFeature', 'activated');
    }
    store.dispatch(activateScreenshare());
};

const deactivateScreenshare = () => {
    return {
        type: DEACTIVATE_SCREEN_SHARE,
    };
};

export const deactivateScreenshareDispatch = async userType => {
    if (userType && userType === 'dispatcher') {
        await dispatcherStore.stopScreenshare();
        createKpiLog('stateScreenshareFeature', 'deactivated');
    }
    dispatchRemoveDispatcherStream();
    store.dispatch(deactivateScreenshare());
};

const openInvitationPanel = () => {
    return {
        type: OPEN_INVITATION_PANEL,
    };
};

export const dispatchOpenInvitationPanel = () => {
    store.dispatch(openInvitationPanel());
};

const closeInvitationPanel = () => {
    return {
        type: CLOSE_INVITATION_PANEL,
    };
};

export const dispatchCloseInvitationPanel = () => {
    store.dispatch(closeInvitationPanel());
};

// LIVE VIDEO LOADING

const setLiveVideoIsLoading = () => {
    return {
        type: SET_LIVE_VIDEO_IS_LOADING,
    };
};

export const dispatchSetLiveVideoIsLoading = () => {
    store.dispatch(setLiveVideoIsLoading());
};

const unsetLiveVideoIsLoading = () => {
    return {
        type: UNSET_LIVE_VIDEO_IS_LOADING,
    };
};

export const dispatchUnsetLiveVideoIsLoading = () => {
    store.dispatch(unsetLiveVideoIsLoading());
};

// CONFERENCE CHAT

const activateChatConference = () => {
    return {
        type: ACTIVATE_CONFERENCE_CHAT,
    };
}

export const activateChatConferenceDispatch = () => {
    store.dispatch(activateChatConference());
};

const deactivateChatConference = () => {
    return {
        type: DEACTIVATE_CONFERENCE_CHAT,
    };
}

export const deactivateChatConferenceDispatch = () => {
    store.dispatch(deactivateChatConference());
};
// EXTERNAL STREAM

const activateExternalStream = () => {
    return {
        type: ACTIVATE_EXTERNAL_STREAM,
    };
};

export const activateExternalStreamDispatch = () => {
    store.dispatch(activateExternalStream());
};

const deactivateExternalStream = () => {
    return {
        type: DEACTIVATE_EXTERNAL_STREAM,
    };
};

export const deactivateExternalStreamDispatch = async () => {
    store.dispatch(deactivateExternalStream());
};

// SMART CONNECT

const activateSmartConnect = () => {
    return {
        type: ACTIVATE_SMART_CONNECT,
    };
};

export const activateSmartConnectDispatch = () => {
    store.dispatch(activateSmartConnect());
};

const deactivateSmartConnect = () => {
    return {
        type: DEACTIVATE_SMART_CONNECT,
    };
};

export const deactivateSmartConnectDispatch = () => {
    store.dispatch(deactivateSmartConnect());
};

// CALLER TAB FOCUS

const setCallerTabInFocus = () => {
    return {
        type: CALLER_TAB_IN_FOCUS,
    };
};

export const setCallerTabInFocusDispatch = () => {
    store.dispatch(setCallerTabInFocus());
};

const setCallerTabNotInFocus = () => {
    return {
        type: CALLER_TAB_NOT_IN_FOCUS,
    };
};

export const setCallerTabNotInFocusDispatch = () => {
    store.dispatch(setCallerTabNotInFocus());
};