import { DEBUG, DISPLAY_ONLY_IN_SESSION, MAX_ALLOWED_CONFERENCE_USERS } from '../config';
import { dispatchAddStreamInfo, dispatchRemoveStreamInfo } from '../redux/actions/conferencing';
import { connectionLostDispatch } from '../redux/actions/connection';
import { dispatchAddSessionRecording } from '../redux/actions/session';
import {
    dispatchAddCallerAudioStream,
    dispatchAddCallerStream,
    dispatchAddConferenceUserStream,
    dispatchAddDispatcherAudioStream,
    dispatchAddDispatcherBidiStream,
    dispatchAddDispatcherStream,
    dispatchRemoveCallerAudioStream,
    dispatchRemoveCallerStream,
    dispatchRemoveConferenceUserStream,
    dispatchRemoveDispatcherAudioStream,
    dispatchRemoveDispatcherBidiStream,
    dispatchRemoveDispatcherStream,
} from '../redux/actions/stream';
import { store as dispatcherStore } from '../store/DispatcherStore';
import { conferenceStore } from '../store/ConferenceStore';
import { addCallerVideoStreamInDiv, addStreamToConferenceUserContainer } from './streamHandling';
import { callerStore } from '../store/CallerStore';
import reduxStore from '../redux/store';
import { addNotificationAndShowDispatch } from '../redux/actions/notifications';
import {
    activateBidiOtherDispatch,
    activateScreenshareDispatch,
    deactivateAudioStreamDispatcherDispatch,
    deactivateBidiOtherDispatch,
    deactivateScreenshareDispatch,
    deactivateVideoCallerDispatch,
    dispatchStartStreamRecording,
    dispatchStopStreamRecording,
} from '../redux/actions/application';
import { createKpiLog } from './helper';
import { postNewRecording } from '../api/backendApi';
import {
    dispatchAddInvitedUser,
    dispatchAddStream,
    dispatchRemoveInvitedUser,
    dispatchRemoveStream,
    dispatchToggleAudioMuted,
} from '../redux/actions/invitedUsers';
import { sixDigitRegex } from '../regex';
import {
    deactivateSessionHijackingDispatch,
    dispatchAddSessionDevice,
    dispatchDeactivateSessionHandover,
    dispatchReplaceCallerDevice,
    dispatchUnsetIsHeadMountedDisplayDevice,
    dispatchUpdateActiveDeviceId,
} from '../redux/actions/sessionHandling';
import { addLogDispatch } from '../redux/actions/logs';

// get URL address and abstract a session id, caller id, and a conversation name
export const getURLParams = () => {
    var parts = window.location.href.split('/');
    var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash
    var preLastSegment = parts.pop() || parts.pop(); // handle potential trailing slash

    return {
        sessionId: lastSegment,
        callerId: preLastSegment,
        conversationName: lastSegment + '' + preLastSegment,
    };
};

// Conference user display name
export const createUserDisplayName = (store, userName) => {
    store.connectedSession.setUsername(userName);
};

export const enterConversation = (store, options = {}) => {
    const { conversationName } = getURLParams();
    store.connectedConversation = store.connectedSession.getOrCreateConversation(conversationName, options);
    if (DEBUG) addLogDispatch(['getOrCreateConversation: ', conversationName]);
};

// Dispatcher screen share stream event handler
export const dispatcherStreamHandlers = stream => {
    stream.on('trackStopped', event => {
        // Used to detect when user stops screen share with Chrome/Firefox
        dispatcherStore.stoppedViaBrowserButton = true;
        deactivateScreenshareDispatch('dispatcher');
        dispatchRemoveDispatcherStream();
        dispatcherStore.sendScreenshareToggled(false);
    });
};

export const unpublishStreamAndRemoveFromRedux = (stream, store) => {
    if (stream) {
        store.connectedConversation.unpublish(stream);
        stream.release();
        dispatchRemoveDispatcherAudioStream();
    }
};

// handlers for the dispatcher session and conversation
export const loadEventListenersDispatcher = () => {
    dispatcherStore.connectedSession.on('contactListUpdate', handleUserJoinedDispatcher);

    dispatcherStore.connectedConversation
        .on('streamAdded', handleStreamAddedDispatcher)
        .on('streamRemoved', handleStreamRemovedDispatcher)
        .on('streamListChanged', handleStreamChangedDispatcher)
        .on('contactJoined', handleContactJoinedDispatcher)
        .on('contactLeft', handleContactLeftDispatcher)
        .on('recordingStarted', function (recordingInfo) {
            let status = 'started';
            dispatchAddSessionRecording({ recordingInfo, status });
            postNewRecording(recordingInfo.mediaId, recordingInfo.mediaURL, recordingInfo.recordedFileName);
        })
        .on('recordingStopped', recordingInfo => {
            let status = 'stopped';
            dispatchAddSessionRecording({ recordingInfo, status });
        })
        .on('recordingAvailable', recordingInfo => {
            dispatchAddSessionRecording({ recordingInfo });
            fetchVideoAndThumbnailAndDispatch(recordingInfo);
        });
};

export const fetchVideoAndThumbnailAndDispatch = recordingInfo => {
    fetch(recordingInfo.mediaURL)
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            getThumbnailURL(url);
        });

    // Function to get the thumbnail URL
    function getThumbnailURL(videoURL) {
        const video = document.createElement('video');
        video.src = videoURL;
        video.currentTime = 1;

        const canvas = document.createElement('canvas');

        const ctx = canvas.getContext('2d');
        video.addEventListener('loadedmetadata', function () {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            video.addEventListener('canplaythrough', function () {
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                setTimeout(function () {
                    const image = canvas.toDataURL();
                    let status = 'available';
                    dispatchAddSessionRecording({ recordingInfo, image, status });
                    return image;
                }, 100); // Delay by 100ms
            });
        });
    }
};

export const handleUserJoinedDispatcher = updatedContact => {
    // check if updatedContact contains any user data
    if (updatedContact.userDataChanged.length > 0) {
        const { callerId } = getURLParams();
        const joinedUser = updatedContact.userDataChanged[0];

        if (DEBUG) addLogDispatch(['user joined session:', joinedUser]);

        /**
         * INVITED USERS ONLY
         **/
        if (reduxStore.getState().features.inviteFeature && joinedUser.userData.username !== callerId) {
            if (reduxStore.getState().invitedUsers.length > 0) {
                reduxStore.getState().invitedUsers.map(currentUser => {
                    // check if user exists via user id to prevent adding a duplicate on name change
                    const userExists = currentUser.userData.id === joinedUser.userData.id;
                    if (userExists) {
                        dispatchRemoveInvitedUser(currentUser);
                    }
                    return null;
                });

                if (joinedUser.userData.username.indexOf('guest-') === -1 && !sixDigitRegex.test(joinedUser.userData.username)) {
                    dispatchAddInvitedUser(joinedUser);
                    const responseMessage = {
                        data: 'joinRequestIsGranted',
                    };

                    dispatcherStore.sendMessageToUser(responseMessage, joinedUser);
                    dispatcherStore.sendSessionData();
                    dispatcherStore.sendApplicationData();
                    dispatcherStore.sendSessionHandlingData();
                }
            } else {
                const newUser = updatedContact.userDataChanged[0];
                // sixDigitRegex prevents users with a webrtc randomly generated 6 digit name from being added
                if (newUser.userData.username.indexOf('guest-') === -1 && !sixDigitRegex.test(newUser.userData.username)) {
                    dispatchAddInvitedUser(newUser);
                    const responseMessage = {
                        data: 'joinRequestIsGranted',
                    };

                    dispatcherStore.sendMessageToUser(responseMessage, newUser);
                    dispatcherStore.sendSessionData();
                    dispatcherStore.sendApplicationData();
                    dispatcherStore.sendSessionHandlingData();
                }
            }
        }

        /**
         * CALLER ONLY
         **/
        // add device with callerId if none yet exist
        if (reduxStore.getState().sessionHandling.devices.length === 0 && joinedUser.userData.username === callerId) {
            dispatchAddSessionDevice(joinedUser);
            dispatchUpdateActiveDeviceId(joinedUser.userData.id);
            dispatcherStore.sendJoinRequestGrantedToUser(joinedUser);
            dispatcherStore.establishConnectivityWithNewCaller(joinedUser);
            return;
        }

        // device with callerId attempts to join
        if (joinedUser.userData.username === callerId && reduxStore.getState().sessionHandling.devices.length !== 0) {
            // check if device has previously joined
            const deviceIdExists = reduxStore.getState().sessionHandling.devices.some(device => device.contact.userData.id === joinedUser.userData.id);

            // while session handover is activated
            if (reduxStore.getState().sessionHandling.sessionHandoverIsActive) {
                // reset hmd
                if (reduxStore.getState().sessionHandling.isHeadMountedDisplayDevice) {
                    dispatchUnsetIsHeadMountedDisplayDevice();
                }
                // send session transfer success message to current caller device
                const currentCallerDevice = reduxStore
                    .getState()
                    .sessionHandling.devices.find(device => device.contact.userData.id === reduxStore.getState().sessionHandling.activeDeviceId);
                dispatcherStore.sendHandoverSessionToCallerDevice(currentCallerDevice.contact);

                // then update active device id
                dispatchUpdateActiveDeviceId(joinedUser.userData.id);

                if (!deviceIdExists) {
                    dispatchAddSessionDevice(joinedUser);
                }

                dispatcherStore.sendJoinRequestGrantedToUser(joinedUser);
                dispatchDeactivateSessionHandover();
                dispatcherStore.establishConnectivityWithNewCaller(joinedUser);
                createKpiLog('sessionHandoverSuccessful', '', { 0: joinedUser.userData.id });
                dispatcherStore.toggleOffAllFeatures();
                addNotificationAndShowDispatch('session.handover.success', 'info', DISPLAY_ONLY_IN_SESSION);
            } else {
                // while session handover is deactivated
                if (deviceIdExists) {
                    // reset hmd
                    if (reduxStore.getState().sessionHandling.isHeadMountedDisplayDevice) {
                        dispatchUnsetIsHeadMountedDisplayDevice();
                    }
                    // hand over the session to rejoining device
                    if (joinedUser.userData.id !== reduxStore.getState().sessionHandling.activeDeviceId) {
                        // send disconnect message to current caller device
                        const currentCallerDevice = reduxStore
                            .getState()
                            .sessionHandling.devices.find(device => device.contact.userData.id === reduxStore.getState().sessionHandling.activeDeviceId);
                        dispatcherStore.sendHandoverSessionToCallerDevice(currentCallerDevice.contact);

                        dispatchUpdateActiveDeviceId(joinedUser.userData.id);
                        dispatcherStore.sendJoinRequestGrantedToUser(joinedUser);
                        dispatcherStore.establishConnectivityWithNewCaller(joinedUser);
                        createKpiLog('callerDeviceChanged', '', { 0: joinedUser.userData.id });
                        addNotificationAndShowDispatch('session.device.changed', 'info', DISPLAY_ONLY_IN_SESSION);
                        dispatcherStore.toggleOffAllFeatures();
                    }
                } else {
                    // handle new caller device attempting to join
                    if (!reduxStore.getState().connection.isConnected || reduxStore.getState().sessionHandling.sessionHijackingIsActive) {
                        // reset hmd
                        if (reduxStore.getState().sessionHandling.isHeadMountedDisplayDevice) {
                            dispatchUnsetIsHeadMountedDisplayDevice();
                        }
                        // if the connection is lost or session hijacking is active, allow new device to hijack the session
                        const currentCallerDevice = reduxStore
                            .getState()
                            .sessionHandling.devices.find(device => device.contact.userData.id === reduxStore.getState().sessionHandling.activeDeviceId);

                        // send disconnect message to current caller device and remove from device list
                        dispatcherStore.sendDispatcherLeftToCaller();

                        // replace current caller device with joining user device
                        dispatchReplaceCallerDevice({ currentDevice: currentCallerDevice, newDevice: joinedUser });
                        dispatchUpdateActiveDeviceId(joinedUser.userData.id);
                        dispatcherStore.sendJoinRequestGrantedToUser(joinedUser);
                        dispatcherStore.establishConnectivityWithNewCaller(joinedUser);
                        dispatcherStore.toggleOffAllFeatures();
                        // TODO: check if kpi necessary here
                        // createKpiLog('callerDeviceChanged', '', joinedUser.userData.id);
                    } else {
                        // send disconnect message to new device attempting to connect
                        dispatcherStore.sendDispatcherLeftToUser(joinedUser);
                    }
                }
            }
            deactivateSessionHijackingDispatch();
        }
    }
};

export const unloadEventListenersDispatcher = () => {
    dispatcherStore.connectedSession.removeListener('contactListUpdate', handleUserJoinedDispatcher);

    dispatcherStore.connectedConversation
        .removeListener('streamListChanged', handleStreamChangedDispatcher)
        .removeListener('streamRemoved', handleStreamRemovedDispatcher)
        .removeListener('contactJoined', handleContactJoinedDispatcher)
        .removeListener('contactLeft', handleContactLeftDispatcher)
        .removeListener('streamAdded', handleStreamAddedDispatcher);
};

// handlers for the caller conversation
export const loadEventListenersCaller = () => {
    callerStore.connectedConversation
        .on('streamAdded', handleStreamAddedCaller)
        .on('streamRemoved', handleStreamRemovedCaller)
        .on('streamListChanged', handleStreamChangedCaller)
        .on('contactLeft', handleContactLeftCaller)
        .on('recordingStarted', recordingInfo => {
            dispatchStartStreamRecording();
        })
        .on('recordingStopped', recordingInfo => {
            dispatchStopStreamRecording();
        });
};

export const unloadEventListenersCaller = () => {
    callerStore.connectedConversation
        .removeListener('streamAdded', handleStreamAddedCaller)
        .removeListener('streamRemoved', handleStreamRemovedCaller)
        .removeListener('streamListChanged', handleStreamChangedCaller)
        .removeListener('contactLeft', handleContactLeftCaller)
        .removeListener('recordingStarted')
        .removeListener('recordingStopped');
};

// handlers for the conference user conversation
export const loadEventListenersConferenceUser = () => {
    conferenceStore.connectedConversation
        .on('streamAdded', handleStreamAddedConferenceUser)
        .on('streamRemoved', handleStreamRemovedConferenceUser)
        .on('streamListChanged', handleStreamChangedConferenceUser)
        .on('contactLeft', handleContactLeftConferenceUser);
};

export const unloadEventListenersConferenceUser = () => {
    if (conferenceStore.connectedConversation !== null) {
        conferenceStore.connectedConversation
            .removeListener('streamAdded', handleStreamAddedConferenceUser)
            .removeListener('streamRemoved', handleStreamRemovedConferenceUser)
            .removeListener('streamListChanged', handleStreamChangedConferenceUser)
            .removeListener('contactLeft', handleContactLeftConferenceUser);
    }
};

// ***********************************
// HANDLE STREAM ADDED TO CONVERSATION

const handleStreamAddedDispatcher = stream => {
    addStream(stream, dispatcherStore);
};

const handleStreamAddedCaller = stream => {
    addStream(stream, callerStore);
};

const handleStreamAddedConferenceUser = stream => {
    addStream(stream, conferenceStore);
};

const addStream = (stream, store) => {
    const { callerId } = getURLParams();

    // DISPATCHER
    if (store.type === 'dispatcher') {
        if (stream.contact.userData.username === callerId) {
            // incoming caller stream
            if (stream.type === 'audio') {
                dispatchAddCallerAudioStream(stream);
                handleIncomingAudio(stream, store);
            }
            if (stream.type === 'video') {
                dispatcherStore.callerStream = stream;
                dispatchAddCallerStream(stream);
                addCallerVideoStreamInDiv(stream, true);
            }
        } else {
            // incoming conference user stream
            const additionalStates = {
                0: stream.contact.userData.username,
            };

            createKpiLog('infoConferenceUser', 'joined', additionalStates);

            dispatchAddConferenceUserStream(stream);
            handleIncomingAudio(stream, store);
            dispatchAddStream(stream);
        }
    }

    // CALLER
    if (store.type === 'caller') {
        if (stream.contact.userData.username === 'dispatcher') {
            // incoming dispatcher stream
            if (stream.type === 'audio') {
                dispatchAddDispatcherAudioStream(stream);
                handleIncomingAudio(stream, store);
            }
            if (stream.type === 'video') {
                reduxStore.getState().conferencing.streamInfo.forEach(streamInfo => {
                    if (stream.streamId === streamInfo.streamId) {
                        // dispatcher only publishes video for screen sharing and bidi
                        if (!streamInfo.isScreensharing) {
                            if (streamInfo.context.bidi) {
                                let bidiContainer = document.getElementById('bidi-container-caller');
                                bidiContainer.innerHTML = '';
                                dispatchAddDispatcherBidiStream(stream);
                                addBidiStreamToCallerContainer(stream);
                                activateBidiOtherDispatch();
                            }
                        }
                        if (streamInfo.isScreensharing) {
                            if (streamInfo.context.screenshare) {
                                let screenshareContainer = document.getElementById('screenshare-container-caller');
                                screenshareContainer.innerHTML = '';
                                dispatchAddDispatcherStream(stream);
                                addScreenshareStreamToCallerContainer(stream);
                                activateScreenshareDispatch();
                            }
                        }
                    }
                });
            }
        } else {
            // incoming conference user audio stream
            dispatchAddConferenceUserStream(stream);
            handleIncomingAudio(stream, store);
        }
    }

    // CONFERENCE USER
    if (store.type === 'conference') {
        if (stream.contact.userData.username === callerId) {
            // incoming caller audio stream
            if (stream.type === 'audio') {
                dispatchAddCallerAudioStream(stream);
                handleIncomingAudio(stream, store);
            }
            // incoming caller video stream
            if (stream.type === 'video') {
                dispatchAddCallerStream(stream);
                addCallerVideoStreamInDiv(stream, false, false, 'caller-stream-container');
            }
        } else if (stream.contact.userData.username === 'dispatcher') {
            // incoming dispatcher audio stream
            if (stream.type === 'audio') {
                dispatchAddDispatcherAudioStream(stream);
                handleIncomingAudio(stream, store);
            }
            // incoming video streams from dispatcher
            if (stream.type === 'video') {
                reduxStore.getState().conferencing.streamInfo.forEach(streamInfo => {
                    if (stream.streamId === streamInfo.streamId) {
                        // screensharing from dispatcher
                        if (streamInfo.isScreensharing) {
                            dispatchAddDispatcherStream(stream);
                            addStreamToConferenceUserContainer(stream, 'screen-share-container', true);
                            activateScreenshareDispatch();
                        }
                        // bidi from dispatcher
                        if (!streamInfo.isScreensharing) {
                            if (streamInfo.context.bidi) {
                                dispatchAddDispatcherBidiStream(stream);
                                addStreamToConferenceUserContainer(stream, 'bidi-stream-container', true);
                                activateBidiOtherDispatch();
                            }
                        }
                    }
                });
            }
        } else {
            // incoming conference user audio streams
            dispatchAddConferenceUserStream(stream);
            handleIncomingAudio(stream, store);
        }
    }
};

const handleIncomingAudio = (stream, store) => {
    // DISPATCHER
    if (store.type === 'dispatcher') {
        if (!reduxStore.getState().application.audioIsMuted) {
            readdAllStreamsToContainerDispatcher();
        }
    }

    // CALLER
    if (store.type === 'caller') {
        if (!reduxStore.getState().application.audioIsMuted) {
            readdAllStreamsToContainerCaller();
        }
    }

    // CONFERENCE USER
    if (store.type === 'conference') {
        addStreamToConferenceUserContainer(stream, 'audio-container');
    }
};

// *************************************
// HANDLE STREAM REMOVED TO CONVERSATION

const handleStreamRemovedDispatcher = stream => {
    removeStream(stream, dispatcherStore);
};

const handleStreamRemovedCaller = stream => {
    removeStream(stream, callerStore);
};

const handleStreamRemovedConferenceUser = stream => {
    removeStream(stream, conferenceStore);
};

const removeStream = (stream, store) => {
    const { callerId } = getURLParams();

    // DISPATCHER
    if (store.type === 'dispatcher') {
        if (stream.contact.userData.username === callerId) {
            // caller stream
            if (stream.type === 'audio') {
                dispatchRemoveCallerAudioStream();
                handleRemovalOfIncomingStream(stream, store);
            }
        } else {
            const additionalStates = {
                0: stream.contact.userData.username,
            };

            createKpiLog('infoConferenceUser', 'left', additionalStates);

            dispatchRemoveConferenceUserStream(stream.streamId);
            handleRemovalOfIncomingStream(stream, store);
            dispatchRemoveStream(stream);
        }
    }

    // CALLER
    if (store.type === 'caller') {
        if (stream.contact.userData.username === 'dispatcher') {
            if (stream.type === 'video') {
                if (reduxStore.getState().streams.dispatcherScreenshareStream) {
                    if (stream.streamId === reduxStore.getState().streams.dispatcherScreenshareStream.streamId) {
                        // dispatcher screen share stream
                        handleRemovalOfIncomingStream(stream, store);
                        dispatchRemoveDispatcherStream();
                        deactivateScreenshareDispatch();
                    }
                }
                reduxStore.getState().conferencing.streamInfo.forEach(streamInfo => {
                    if (stream.streamId === streamInfo.streamId) {
                        if (!streamInfo.isScreensharing && !streamInfo.context.recording) {
                            handleRemovalOfIncomingStream(stream, store);
                            dispatchRemoveDispatcherBidiStream();
                            if (reduxStore.getState().application.bidiIsActive) {
                                deactivateBidiOtherDispatch();
                            }
                        }
                    }
                });
            }
        }

        if (reduxStore.getState().streams.dispatcherAudioStream !== null) {
            if (stream.type !== 'video') {
                if (stream.streamId === reduxStore.getState().streams.dispatcherAudioStream.streamId) {
                    // dispatcher audio stream
                    dispatchRemoveDispatcherAudioStream();
                    handleRemovalOfIncomingStream(stream, store);
                } else {
                    // conference user stream
                    dispatchRemoveConferenceUserStream(stream.streamId);
                    handleRemovalOfIncomingStream(stream, store);
                }
            }
        }
    }

    // CONFERENCE
    if (store.type === 'conference') {
        if (reduxStore.getState().streams.dispatcherAudioStream) {
            if (stream.streamId === reduxStore.getState().streams.dispatcherAudioStream.streamId) {
                // dispatcher audio stream
                dispatchRemoveDispatcherAudioStream();
                handleRemovalOfIncomingStream(stream, store);
            }
        }

        if (reduxStore.getState().streams.conferenceUserStreams.length !== 0) {
            reduxStore.getState().streams.conferenceUserStreams.forEach(reduxStream => {
                if (stream.streamId === reduxStream.streamId) {
                    // conference user stream
                    dispatchRemoveConferenceUserStream(stream.streamId);
                    handleRemovalOfIncomingStream(stream, store);
                }
            });
        }

        if (reduxStore.getState().streams.callerAudioStream) {
            if (stream.streamId === reduxStore.getState().streams.callerAudioStream.streamId) {
                // caller audio stream
                dispatchRemoveCallerAudioStream();
                handleRemovalOfIncomingStream(stream, store);
            }
        }

        if (reduxStore.getState().streams.dispatcherScreenshareStream) {
            if (stream.streamId === reduxStore.getState().streams.dispatcherScreenshareStream.streamId) {
                // dispatcher screen share stream
                dispatchRemoveDispatcherStream();
                handleRemovalOfIncomingStream(stream, store);
                deactivateScreenshareDispatch();
            }
        }
        if (reduxStore.getState().streams.dispatcherBidiStream) {
            if (stream.streamId === reduxStore.getState().streams.dispatcherBidiStream.streamId) {
                // dispatcher bidi stream
                handleRemovalOfIncomingStream(stream, store);
                dispatchRemoveDispatcherBidiStream();
                deactivateBidiOtherDispatch();
            }
        }
        if (reduxStore.getState().streams.callerStream) {
            if (stream.streamId === reduxStore.getState().streams.callerStream.streamId) {
                // caller video stream
                dispatchRemoveCallerStream();
                handleRemovalOfIncomingStream(stream, store);
                deactivateVideoCallerDispatch();
            }
        }
    }
};

const handleRemovalOfIncomingStream = (stream, store) => {
    const { callerId } = getURLParams();

    // DISPATCHER
    if (store.type === 'dispatcher') {
        if (stream.contact.userData.username === callerId) {
            // caller audio stream
            if (reduxStore.getState().application.audioStreamIsActive) {
                addNotificationAndShowDispatch('info.aud_lst', 'info', DISPLAY_ONLY_IN_SESSION);
            }

            deactivateAudioStreamDispatcherDispatch();
        }

        stream.removeFromDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId);
    }

    // CALLER
    if (store.type === 'caller') {
        if (stream.type === 'video') {
            if (
                reduxStore.getState().streams.dispatcherBidiStream &&
                reduxStore.getState().streams.dispatcherBidiStream.streamId &&
                reduxStore.getState().streams.dispatcherBidiStream.streamId === stream.streamId
            ) {
                stream.removeFromDiv('bidi-container-caller', 'bidi-stream-' + stream.streamId);
                let bidiContainer = document.getElementById('bidi-container-caller');
                bidiContainer.innerHTML = '';
            } else if (
                reduxStore.getState().streams.dispatcherScreenshareStream &&
                reduxStore.getState().streams.dispatcherScreenshareStream.streamId &&
                reduxStore.getState().streams.dispatcherScreenshareStream.streamId === stream.streamId
            ) {
                stream.removeFromDiv('screenshare-container-caller', 'screeenshare-stream-' + stream.streamId);
                let screenshareContainer = document.getElementById('screenshare-container-caller');
                screenshareContainer.innerHTML = '';
            }
        } else {
            stream.removeFromDiv('audio-container-caller', 'stream-media-' + stream.streamId);
        }
    }

    // CONFERENCE USER
    if (store.type === 'conference') {
        if (stream.type === 'video') {
            if (
                reduxStore.getState().streams.dispatcherBidiStream &&
                reduxStore.getState().streams.dispatcherBidiStream.streamId &&
                reduxStore.getState().streams.dispatcherBidiStream.streamId === stream.streamId
            ) {
                stream.removeFromDiv('bidi-stream-container', 'stream-media-' + stream.streamId);
                let bidiContainer = document.getElementById('bidi-stream-container');
                bidiContainer.innerHTML = '';
            } else if (
                reduxStore.getState().streams.dispatcherScreenshareStream &&
                reduxStore.getState().streams.dispatcherScreenshareStream.streamId &&
                reduxStore.getState().streams.dispatcherScreenshareStream.streamId === stream.streamId
            ) {
                stream.removeFromDiv('screen-share-container', 'stream-media-' + stream.streamId);
                let screenshareContainer = document.getElementById('screen-share-container');
                screenshareContainer.innerHTML = '';
            } else if (
                reduxStore.getState().streams.callerStream &&
                reduxStore.getState().streams.callerStream.streamId &&
                reduxStore.getState().streams.callerStream.streamId === stream.streamId
            ) {
                stream.removeFromDiv('caller-stream-container', 'stream');
                let callerVideoContainer = document.getElementById('caller-stream-container');
                callerVideoContainer.innerHTML = '';
            }
        } else {
            stream.removeFromDiv('audio-container', 'stream-media-' + stream.streamId);
        }
    }
};

// ******************************************
// ADD AND REMOVE STREAMS TO CONTAINER CALLER

export const addBidiStreamToCallerContainer = stream => {
    stream.addInDiv('bidi-container-caller', 'bidi-stream-' + stream.streamId, {}, true);
};

export const addScreenshareStreamToCallerContainer = stream => {
    stream.addInDiv('screenshare-container-caller', 'screeenshare-stream-' + stream.streamId, {}, true);
};

export const addAllStreamsToContainerCaller = () => {
    if (reduxStore.getState().streams.dispatcherAudioStream) {
        let stream = reduxStore.getState().streams.dispatcherAudioStream;
        stream.addInDiv('audio-container-caller', 'stream-media-' + stream.streamId, {}, false);
    }
    if (reduxStore.getState().streams.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streams.conferenceUserStreams.forEach(stream => {
            stream.addInDiv('audio-container-caller', 'stream-media-' + stream.streamId, {}, false);
        });
    }
};

export const removeAllStreamsFromContainerCaller = () => {
    if (reduxStore.getState().streams.dispatcherAudioStream) {
        let stream = reduxStore.getState().streams.dispatcherAudioStream;
        stream.removeFromDiv('audio-container-caller', 'stream-media-' + stream.streamId, {}, false);
    }
    if (reduxStore.getState().streams.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streams.conferenceUserStreams.forEach(stream => {
            stream.removeFromDiv('audio-container-caller', 'stream-media-' + stream.streamId, {}, false);
        });
    }
};

// **********************************************
// ADD AND REMOVE STREAMS TO CONTAINER DISPATCHER

export const readdAllStreamsToContainerDispatcher = () => {
    removeAllStreamsFromContainerDispatcher();
    addAllStreamsToContainerDispatcher();
};

export const readdAllStreamsToContainerCaller = () => {
    removeAllStreamsFromContainerCaller();
    addAllStreamsToContainerCaller();
};

export const addAllStreamsToContainerDispatcher = () => {
    if (reduxStore.getState().streams.callerAudioStream) {
        let stream = reduxStore.getState().streams.callerAudioStream;
        stream.addInDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
    }
    if (reduxStore.getState().streams.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streams.conferenceUserStreams.forEach(stream => {
            stream.addInDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
        });
    }
};

export const removeAllStreamsFromContainerDispatcher = () => {
    if (reduxStore.getState().streams.callerAudioStream) {
        let stream = reduxStore.getState().streams.callerAudioStream;
        stream.removeFromDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
    }
    if (reduxStore.getState().streams.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streams.conferenceUserStreams.forEach(stream => {
            stream.removeFromDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
        });
    }
};

// **********************
// STREAM CHANGED HANDLER

const handleStreamChangedDispatcher = streamInfo => {
    changeStream(streamInfo, dispatcherStore);
};

const handleStreamChangedCaller = streamInfo => {
    changeStream(streamInfo, callerStore);
};

const handleStreamChangedConferenceUser = streamInfo => {
    changeStream(streamInfo, conferenceStore);
};

const changeStream = (streamInfo, store) => {
    if (streamInfo.isRemote === true) {
        if (streamInfo.listEventType === 'added') {
            dispatchAddStreamInfo(streamInfo);
            store.connectedConversation
                .subscribeToMedia(streamInfo.streamId)
                .then(stream => {
                    console.log('subscribeToMedia succeeded');

                    dispatchToggleAudioMuted({ userId: streamInfo.contact.userData.id, isAudioMuted: streamInfo.isAudioMuted });
                })
                .catch(err => {
                    console.error('subscribeToMedia error', err);
                });
        }
        if (streamInfo.listEventType === 'removed') {
            dispatchRemoveStreamInfo(streamInfo.streamId);
        }
        if (streamInfo.listEventType === 'updated') {
            for (const previousStreamInfo of reduxStore.getState().conferencing.streamInfo) {
                if (streamInfo.streamId === previousStreamInfo.streamId) {
                    dispatchRemoveStreamInfo(previousStreamInfo.streamId);
                    dispatchAddStreamInfo(streamInfo);

                    // toggles the audio muted information in the conference user list
                    dispatchToggleAudioMuted({ userId: streamInfo.contact.userData.id, isAudioMuted: streamInfo.isAudioMuted });
                }
            }
        }
    }
};

// **************
// CONTACT JOINED

const handleContactJoinedDispatcher = contactInfo => {
    const { callerId } = getURLParams();

    if (!contactInfo.userData.username === callerId && reduxStore.getState().invitedUsers.length < MAX_ALLOWED_CONFERENCE_USERS) {
        dispatcherStore.sendSessionData();
        dispatcherStore.sendApplicationData();
        dispatcherStore.sendSessionHandlingData();
    }
};

// ************
// CONTACT LEFT

const handleContactLeftDispatcher = contactInfo => {
    const { callerId } = getURLParams();
    if (contactInfo.userData.username === callerId && contactInfo.userData.id === reduxStore.getState().sessionHandling.activeDeviceId) {
        connectionLostDispatch();
    } else {
        dispatchRemoveInvitedUser(contactInfo);
    }
};

const handleContactLeftConferenceUser = contactInfo => {
    if (contactInfo.userData.username === 'dispatcher') {
        conferenceStore.onLeaveHandler();
    }
};

const handleContactLeftCaller = contactInfo => {
    if (contactInfo.userData.username === 'dispatcher') {
        if (callerStore.connectedConversation !== null) {
            callerStore.connectedConversation.leave();
        }
        if (callerStore.disconnectCallback) {
            callerStore.disconnectCallback();
            callerStore.clearAllTimeouts();
            clearInterval(callerStore.checkHeartbeatInterval);
        }
    }
};
